import React, { useState } from 'react';
import ConfigManagementTable from './ConfigManagementTable';
import AddConfigManagement from './AddConfigManagement';
import EditConfigManagement from './EditConfigManagement';
import HOVAlert from '../../components/hovAlert/HOVAlert';

const ConfigManagement = () => {
	const [create, setCreate] = useState(false);
	const [edit, setEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("success");
	const [id, setId] = useState(0);

	const handleAddConfigManagementOpen = () => {
		setCreate(true);
	};

	const handleAddConfigManagementClose = () => {
		setCreate(false);
	};

	const handleEditConfigManagementOpen = () => {
		setEdit(true);
	};

	const handleEditConfigManagementClose = () => {
		setEdit(false);
	};

	const handleSnackClose = () => {
		setOpen(false);
	};


	return (
		<>
			{
				(
					create && <AddConfigManagement
						handleAddConfigManagementClose={handleAddConfigManagementClose}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						create={create}
					/>
				)
				||
				(
					edit && <EditConfigManagement
						handleEditConfigManagementClose={handleEditConfigManagementClose}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						edit={edit}
						id={id}
					/>
				)
				||
				(
					<ConfigManagementTable 
						handleAddConfigManagementOpen={handleAddConfigManagementOpen}
						handleEditConfigManagementOpen={handleEditConfigManagementOpen}
						getVpc={setId}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						setConfigId={setId}
					/>
				)
			}
			<HOVAlert 
				open={open}
				onClose={handleSnackClose}
				severity={severity}
				message={message}
				duration={6000}
			/>
		</>
	);
};

export default ConfigManagement;