import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import useApiCalls from '../../hooks/useApiCalls';

const AddBusinessUnit = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const drawerState = useContext(DrawerContext).drawerState;
  const [add, setAdd] = useState(false);
  const {
    addRecord,
    useHttpStatus,
    useMessage,
    useOpen,
    useSeverity
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleAddBusinessUnitClose,
    create
  } = props;

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (add) {
        setOpen(useOpen);
        setMessage(useMessage);
        setSeverity(useSeverity);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleAddBusinessUnitClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useHttpStatus, useMessage, useOpen]);

  const values = {
    header: "Add Business Unit",
    create: create,
    handleClose: handleAddBusinessUnitClose
  };

  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        <Formik
          initialValues={{
            businessUnit: '',
            businessUnitLongName: '',
            accountId: []
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => {
            setBackdrop(true);
            setAdd(false);
            try {
              const keys = Object.keys(values);
              const keysLength = keys.length;

              let formatedValues = {
                businessUnit: '',
                businessUnitLongName: '',
                accountId: ''
              };

              // filtering only values and removing labels
              const filteredValues = keys.map((key) => {
                if (values[key] instanceof Array) {
                  return values[key].map(
                    (singleArrayValue) => singleArrayValue.value
                  );
                }
                return values[key];
              });

              // mapping filtered values to formatedValues
              for (let index = 0; index < keysLength; ++index) {
                let key = keys[index];
                formatedValues[key] = filteredValues[index];
              }

              // Account Id validation
              const accountIdExp = new RegExp(/^[0-9]+$/)

              for (let id = 0; id < formatedValues["accountId"].length; id++) {
                if (formatedValues["accountId"][id].length < 12 || !accountIdExp.test(formatedValues["accountId"][id])) {
                  setOpen(true);
                  setMessage(`Please enter valid account id`);
                  setSeverity("error");
                  setBackdrop(false);
                  return;
                }
              }

              // addBusinessUnit(formatedValues);
              addRecord('bizunit', formatedValues, 'SET_BUSINESSUNIT_DATA');
              setAdd(true);
            }
            catch (error) {
              setOpen(true);
              setMessage(error.message);
              setSeverity("error");
              setBackdrop(false);
            }
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Business Unit"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.businessUnit}
                      name="businessUnit"
                    />
                    {formikProps.errors.businessUnit &&
                      formikProps.touched.businessUnit ? (
                      <div className="text-danger">
                        {formikProps.errors.businessUnit}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Business Unit Long Name"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.businessUnitLongName}
                      name="businessUnitLongName"
                    />
                    {formikProps.errors.businessUnitLongName &&
                      formikProps.touched.businessUnitLongName ? (
                      <div className="text-danger">
                        {formikProps.errors.businessUnitLongName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Account ID(s):"
                      value={formikProps.values.accountId}
                      onChange={(e) => formikProps.setFieldValue(`accountId`, e)}
                      name="accountId"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="account"
                      required
                    />
                    {formikProps.errors.accountId &&
                      formikProps.touched.accountId ? (
                      <div className="text-danger">
                        {formikProps.errors.accountId}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                  <div>
                    <HOVButton
                      value="Add"
                      color="#fff"
                      backgroundColor="#008542"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      type="submit"
                      onClick={formikProps.handleSubmit}
                    />
                  </div>
                  <span className="mx-3"></span>
                  <div>
                    <HOVButton
                      value="Cancel"
                      color="#fff"
                      backgroundColor="#FF4E50"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      onClick={() => {
                        handleAddBusinessUnitClose();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default AddBusinessUnit;

const ValidationSchema = Yup.object().shape({
  businessUnit: Yup.string().required('Please provide a business unit name.'),
  businessUnitLongName: Yup.string().required(
    'Please provide a business unit long name.'
  ),
  accountId: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Please enter account Id'),
});
