import React, { useState } from 'react';
import BUGITable from './BUGITable';
import CreateRequest from './CreateRequest';
import EditRequest from './EditRequest';
import BUGILogs from './BUGILogs';
import BUGIUserScript from './BUGIUserScript';
import HOVAlert from '../../components/hovAlert/HOVAlert';

const BUGI = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [logs, setLogs] = useState(false);
  const [id, setId] = useState(0);
  const [showScript, setShowScript] = useState(false);

  const handleCreateRequestOpen = () => {
    setCreate(true);
  };

  const handleCreateRequestClose = () => {
    setCreate(false);
  };

  const handleEditRequestOpen = () => {
    setEdit(true);
  };

  const handleEditRequestClose = () => {
    setEdit(false);
  };

  const handleLogsOpen = () => {
    setLogs(true);
  };

  const handleLogsClose = () => {
    setLogs(false);
  };

  const handleSnackClose = () => {
    setOpen(false);
  };

  const handleScriptOpen = () => {
    setShowScript(true);
  }

  const handleScriptClose = () => {
    setShowScript(false);
  }


  return (
    <>
      {
        (
          create && <CreateRequest
            handleCreateRequestClose={handleCreateRequestClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            create={create}
          />
        )
        ||
        (
          edit && <EditRequest
            handleEditRequestClose={handleEditRequestClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            edit={edit}
            id={id}
          />
        )
        ||
        (
          logs && <BUGILogs
            handleLogsClose={handleLogsClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            logs={logs}
            id={id}
          />
        )
        ||
        (
          showScript && <BUGIUserScript
            handleScriptClose={handleScriptClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            showScript={showScript}
            id={id}
          />
        )
        ||
        (
          <BUGITable
            handleCreateRequestOpen={handleCreateRequestOpen}
            handleEditRequestOpen={handleEditRequestOpen}
            handleLogsOpen={handleLogsOpen}
            handleScriptOpen={handleScriptOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            setRequestId={setId}
          />
        )
      }
      <HOVAlert
        open={open}
        onClose={handleSnackClose}
        severity={severity}
        message={message}
        duration={2000}
      />
    </>
  );
};

export default BUGI;