import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { AxiosClient } from '../../lib/http';
import Loader from '../../components/loader/Loader';
import './BUGI.scss';

const BUGILogs = (props) => {
  const [attributeData, setAttributeData] = useState(null);
  const [overallStatus, setOverallStatus] = useState("");
  const [baseAmi, setBaseAmi] = useState("");
  const [baseAmiName, setBaseAmiName] = useState("");
  const [buAmiRegions, setBuAmiRegions] = useState([]);
  const [buAmi, setBuAmi] = useState({});
  const [buAmiName, setBuAmiName] = useState("");
  const [s3Logs, setS3Logs] = useState([]);
  const [stackStatus, setStackStatus] = useState("");
  const { biz_unit, os_type, biz_unit_ami_name } = props.id;

  const getLogs = async () => {
    try {
      const response = await AxiosClient.get(`bu-gi-logs/${biz_unit}/${os_type}/${biz_unit_ami_name}`);
      const logs = response.data;

      // Get overall status
      const overall_status = logs.shift();
      setOverallStatus(overall_status.Overall_Status);

      // Get stack status
      const stack_status = logs.shift();
      setStackStatus(stack_status.Stack_Status);


      if (stack_status.Stack_Status === 'CREATE_COMPLETE' || stack_status.Stack_Status === 'UPDATE_COMPLETE') {
        // Get base and BU ami ids
        const amiIds = logs.shift();

        setBaseAmi(amiIds.Base_AmiId);
        setBaseAmiName(amiIds.Base_Ami_Name);
        setBuAmi(amiIds.BU_AmiId);
        setBuAmiName(amiIds.BU_Ami_Name);

        if (amiIds.BU_AmiId !== 'none') {
          setBuAmiRegions(Object.keys(amiIds.BU_AmiId));
        }


        // Get S3 logs
        const s3_logs = logs.pop();

        // Spliting each line
        const logs_arr = s3_logs.s3_script_logs.split("\n");

        let logsArr = [];

        for (let i = 0; i < logs_arr.length; i++) {
          let temp = logs_arr[i].split(",");

          if (temp.length > 0) {
            for (let j = 0; j < temp.length; j++) {
              logsArr.push(temp[j]);
            }
          }
        }

        let tempLogs = [];

        for (let i = 0; i < logsArr.length; i++) {
          let temp = logsArr[i].split(" ");


          if (temp.length > 1) {
            let sh = temp.shift();
            tempLogs.push({ time: sh, value: temp.join(" ") });
            if (tempLogs[tempLogs.length - 1].time === '') {
              tempLogs[tempLogs.length - 1].time = tempLogs[tempLogs.length - 2].time;
            }
          }
          else {
            let t = tempLogs[tempLogs.length - 1].time;
            tempLogs.push({ time: t, value: temp.join(" ") });
            if (tempLogs[tempLogs.length - 1].time === '') {
              tempLogs[tempLogs.length - 1].time = tempLogs[tempLogs.length - 2].time;
            }
          }
        }
        tempLogs.pop();
        setS3Logs(tempLogs);

        const tempData = response.data.map((item) => {
          if (item.Step_Status === "COMPLETED") {
            return {
              ...item,
              color: "#008542"
            }
          }
          else if (item.Step_Status === "FAILED") {
            return {
              ...item,
              color: "#FF4E50"
            }
          }
          else {
            return {
              ...item,
              color: "#0288d1"
            }
          }
        })

        setAttributeData(tempData);
      }
      else {
        // Storing cft logs
        setAttributeData(logs);
      }
    }

    catch (error) {
      props.setOpen(true);
      props.setSeverity("error");
      if (error.response.data.message) {
        props.setMessage(error.response.data.message);
      }
      else {
        props.setMessage(error.message);
      }

      setTimeout(() => {
        props.handleLogsClose();
      }, 2000);
    }
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getLogs();
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const values = {
    header: "GI Run Logs",
    create: props.logs,
    handleClose: props.handleLogsClose
  };


  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        {
          attributeData ?
            (
              <div className='logs-container'>
                <div className='overall-status'>
                  <Typography className="headers">
                    Overall Status:
                  </Typography>
                  <Typography
                    variant='body2'
                    className='status'
                    style={overallStatus === "COMPLETED" ? { background: "#008542" } : { background: "#FF4E50" }}
                  >
                    {overallStatus}
                  </Typography>
                </div>
                <Divider />
                {
                  stackStatus === 'CREATE_COMPLETE' || stackStatus === 'UPDATE_COMPLETE'
                    ? (
                      <>
                        <div className='ami'>
                          <Typography className="headers">
                            Base AMI Id:
                          </Typography>
                          <Typography variant='body1' className='base-ami'>
                            {baseAmi}
                          </Typography>
                        </div>
                        <Divider />
                        <div className='ami'>
                          <Typography className="headers">
                            Base AMI Name:
                          </Typography>
                          <Typography variant='body1' className='base-ami'>
                            {baseAmiName}
                          </Typography>
                        </div>
                        <Divider />
                        <div className='ami'>
                          <Typography className="headers">
                            BU AMI Name:
                          </Typography>
                          <Typography variant='body1' className='base-ami'>
                            {buAmiName}
                          </Typography>
                        </div>
                        <Divider />
                        <div className='ami'>
                          <Typography className="headers" component={"div"}>
                            BU AMI Id(s):
                          </Typography>
                        </div>
                        <div className='rows-view'>
                          <div className='row-headers'>
                            <Typography className='table-header'>Region</Typography>
                            <Typography className='table-header'>AMI Id</Typography>
                          </div>
                          <div className='row-val' style={{ height: "fit-content", overflow: "hidden" }}>
                            {
                              buAmiRegions.map((item, index) => (
                                <div className='steps' key={index}>
                                  <div className='header'>
                                    <Typography
                                      variant='body2'
                                      style={{ textTransform: "none", padding: '5px 0' }}
                                      className='value'
                                    >
                                      {item}
                                    </Typography>
                                  </div>
                                  <div className='header'>
                                    <Typography
                                      className='value'
                                      variant='body2'
                                      style={{ textTransform: "lowercase", padding: '5px 0' }}
                                    >
                                      {buAmi[item]}
                                    </Typography>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <Divider />
                        <div className='step-status'>
                          <Typography className="headers" component={"div"}>Steps Status:</Typography>
                        </div>
                        <div className='rows-view'>
                          <div className='row-headers'>
                            <Typography className='table-header'>Name</Typography>
                            <Typography className='table-header'>Status</Typography>
                            <Typography className='table-header'>Logs</Typography>
                          </div>
                          <div className='row-val'>
                            {
                              attributeData.map((item, index) => (
                                <div className='steps' key={index}>
                                  <div className='header'>
                                    <Typography
                                      variant='overline'
                                      style={{ textTransform: "none", padding: 0 }}
                                      className='value'
                                    >
                                      {item.Step_Name}
                                    </Typography>
                                  </div>
                                  <div className='header'>
                                    <Typography
                                      className='value'
                                      variant='overline'
                                      style={{ background: item.color, color: "#fff", textTransform: "lowercase" }}
                                    >
                                      {item.Step_Status}
                                    </Typography>
                                  </div>
                                  <div className='header'>
                                    <Typography
                                      variant='overline'
                                      style={{ padding: 0, textTransform: "none" }}
                                      className='value'
                                    >
                                      {item.Failure_Message}
                                    </Typography>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <Divider />
                        <div className="s3-scripts-logs">
                          <Typography className="headers">S3 Script Logs: </Typography>
                        </div>
                        <div className='s3-logs'>
                          {
                            s3Logs.length > 0 && s3Logs.map((item, i) => (
                              <div className='s3' key={i}>
                                <div className='log-time'>
                                  <Typography variant='caption'>{item.time}</Typography>
                                </div>
                                <div className='log-value'>
                                  <Typography variant='caption'>{item.value}</Typography>
                                </div>
                              </div>
                            ))
                          }
                          {
                            s3Logs.length <= 0 && <Typography variant='body1'>Empty...</Typography>
                          }
                        </div>
                      </>
                    )
                    :
                    (
                      <>
                        <div className='step-status'>
                          <Typography className="headers" component={"div"}>Cloudformation Logs:</Typography>
                        </div>
                        <div className='rows-view'>
                          <div className='row-headers'>
                            <Typography className='table-header'>Resource Id</Typography>
                            <Typography className='table-header'>Resource Status Reason</Typography>
                          </div>
                          <div className='row-val'>
                            {
                              attributeData.map((item, index) => (
                                <div className='steps' key={index}>
                                  <div className='header'>
                                    <Typography
                                      variant='body2'
                                      style={{ textTransform: "none", padding: 0 }}
                                      className='value'
                                    >
                                      {item.ResourceId}
                                    </Typography>
                                  </div>
                                  <div className='header'>
                                    <Typography
                                      className='value'
                                      variant='overline'
                                      style={{ paddingLeft: 0, textAlign: "left", textTransform: "none" }}
                                    >
                                      {item.ResourceStatusReason}
                                    </Typography>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </>
                    )
                }
              </div>
            )
            :
            (
              (
                <div style={{ height: "60vh" }}>
                  <Loader />
                </div>
              )
            )
        }
      </div>
    </div>
  );
};

export default BUGILogs;
