import React from 'react';
import './error.scss';

const ErrorFallback = () => {
	return (
		<div className='error-container'>
			<h3>Something went wrong</h3>
			<p>Try reloading the page or contact administrator</p>
		</div>
	)
};

export default ErrorFallback;