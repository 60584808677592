import React, { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DrawerContext from '../../context/DrawerContext';

export const HOVAlert = ({
  onClose,
  severity,
  message,
  open,
  style,
  onExit,
  duration = 2000
}) => {
  const drawerState = useContext(DrawerContext).drawerState;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionProps={{ onExited: onExit }}
      style={drawerState ? { marginLeft: 110 } : { marginLeft: 50 }}
    >
      <Alert onClose={onClose} severity={severity} style={{ alignItems: "center" }}>
        {typeof (message) == 'object' ? message?.message : message}
      </Alert>
    </Snackbar>
  );
};

export default HOVAlert;