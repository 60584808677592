import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  CircularProgress,
  Backdrop,
  IconButton
} from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import Loader from '../../components/loader/Loader';
import useApiCalls from '../../hooks/useApiCalls';

const EditRequest = (props) => {
  const [backdropOpen, setBackdrop] = useState(false);
  const [attributeData, setAttributeData] = useState(null);
  const drawerState = useContext(DrawerContext).drawerState;
  const [inputs, setInputs] = useState([]);
  const [update, setUpdate] = useState(false);
  const {
    getFormData,
    updateFormData,
    getInstanceTypes,
    useMessage,
    useSeverity,
    useOpen,
    useAttributeData,
    useHttpStatus,
    useError
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleEditRequestClose,
    edit,
    id
  } = props;
  const { biz_unit, os_type, biz_unit_ami_name } = id;


  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getFormData(`request/${biz_unit}/${os_type}/${biz_unit_ami_name}`);
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (useAttributeData) {
        setAttributeData(useAttributeData);
        let result = [];
        let account_regions = useAttributeData?.target_accounts_regions;

        for (let i = 0; i < account_regions?.length; i++) {
          result.push({
            accounts: [{ label: account_regions[i].account_id, value: account_regions[i].account_id }],
            regions: []
          });

          for (let j = 0; j < account_regions[i].region.length; j++) {
            result[i].regions.push({
              label: account_regions[i].region[j],
              value: account_regions[i].region[j]
            });
          }
        }
        setInputs(result);
      }
      else {
        setOpen(useOpen);
        setMessage(useMessage);
        setSeverity(useSeverity);
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAttributeData, useOpen, useSeverity, useMessage]);

  useMemo(() => {
    if (attributeData?.os_type) {
      getInstanceTypes(`instancetypes/${attributeData?.os_type}`, "Set_Instance_Types_List");
      setBackdrop(true);

      setTimeout(() => {
        setBackdrop(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeData?.os_type])

  // For showing any error while fetching form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (useError) {
        setMessage(useMessage);
        setSeverity(useSeverity);
        setOpen(useOpen);

        setTimeout(() => {
          handleEditRequestClose();
        }, 2000);
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useError, useMessage, useSeverity, useOpen]);

  // Checking for update success/failure
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (update) {
        setMessage(useMessage);
        setSeverity(useSeverity);
        setOpen(useOpen);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleEditRequestClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMessage, useHttpStatus, useOpen]);

  const handleFormChange = (e, index, fieldName) => {
    let newFormValues = [...inputs];
    newFormValues[index][fieldName] = e;
    setInputs(newFormValues);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { accounts: "", regions: [] }])
  };

  const handleRemoveInput = (index) => {
    if (inputs.length !== 1) {
      let newFormValues = [...inputs];
      newFormValues.splice(index, 1);
      setInputs(newFormValues);
    }
  };

  const values = {
    header: "Edit GI Request",
    edit: edit,
    handleClose: handleEditRequestClose
  };

  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        {
          attributeData ?
            (
              <Formik
                initialValues={{
                  bu_name: attributeData?.bu_name,
                  os_type: attributeData?.os_type,
                  bu_ami_name: attributeData?.bu_ami_name,
                  s3: attributeData?.s3,
                  script: attributeData?.script,
                  root_volume_size: attributeData?.root_volume_size,
                  // instance_type: attributeData?.instance_type,
                  instance_type: [{
                    label: attributeData?.instance_type,
                    value: attributeData?.instance_type
                  }],
                  stack_status: attributeData?.stack_status,
                  pipeline_last_run: attributeData?.pipeline_last_run,
                  pipeline_status: attributeData?.pipeline_status
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values) => {
                  setBackdrop(true);
                  setUpdate(false);
                  try {
                    const keys = Object.keys(values);
                    const keysLength = keys.length;

                    let formatedValues = {
                      bu_name: '',
                      os_type: '',
                      s3: '',
                      script: '',
                      bu_ami_name: '',
                      instance_type: '',
                      root_volume_size: '',
                      target_accounts_regions: []
                    };

                    // filtering only values and removing labels
                    const filteredValues = keys.map((key) => {
                      if (values[key] instanceof Array) {
                        return values[key].map(
                          (singleArrayValue) => singleArrayValue.value
                        );
                      }
                      return values[key];
                    });

                    // mapping filtered values to formatedValues
                    for (let index = 0; index < keysLength; ++index) {
                      let key = keys[index];
                      formatedValues[key] = filteredValues[index];
                    }

                    // Check for script file extention
                    let scriptExtention = formatedValues["script"].split(".");

                    if (scriptExtention[scriptExtention.length - 1] !== 'sh' && scriptExtention[scriptExtention.length - 1] !== "ps1") {
                      setOpen(true);
                      setSeverity("error");
                      setMessage("Script file can only have .sh or .ps1 extention");
                      setBackdrop(false);
                      return;
                    }

                    // mapping target account ids with target regions
                    for (let i = 0; i < inputs.length; i++) {
                      if (inputs[i].regions.length > 0) {
                        if (inputs[i].accounts.length > 0) {
                          formatedValues["target_accounts_regions"].push({
                            account_id: inputs[i].accounts[0].value,
                            region: []
                          })

                          for (let j = 0; j < inputs[i].regions.length; j++) {
                            formatedValues["target_accounts_regions"][i].region.push(inputs[i].regions[j].value);
                          }
                        }
                        else {
                          setOpen(true);
                          setSeverity("error");
                          setMessage("Please select an account id");
                          setBackdrop(false);
                          return;
                        }
                      }
                      else {
                        setOpen(true);
                        setSeverity("error");
                        setMessage(`No region selected for account id ${inputs[i].accounts[0].value}`);
                        setBackdrop(false);
                        return;
                      }
                    };

                    let accountsList = [];

                    formatedValues["target_accounts_regions"].forEach(item => {
                      accountsList.push(item.account_id)
                    });

                    // check for duplicate account id
                    let duplicates = accountsList.filter(
                      (item, index) => accountsList.indexOf(item) !== index
                    );

                    if (duplicates.length > 0) {
                      setOpen(true);
                      setSeverity("error");
                      setMessage(`Duplicate account id selected ${duplicates[0]}`);
                      setBackdrop(false);
                      return;
                    }

                    formatedValues["instance_type"] = formatedValues["instance_type"][0];

                    updateFormData(`request/${biz_unit}/${os_type}/${biz_unit_ami_name}`, formatedValues, 'SET_GI_DATA', 'request');
                    setUpdate(true);

                  } catch (error) {
                    setOpen(true);
                    setMessage(error.message);
                    setSeverity("error");
                    setBackdrop(false);
                  }
                }}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Business Unit"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.bu_name}
                            name="bu_name"
                            disable
                            description="Select Business Unit"
                          />
                          {formikProps.errors.bu_name &&
                            formikProps.touched.bu_name ? (
                            <div className="text-danger">
                              {formikProps.errors.bu_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="OS Type"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.os_type}
                            name="os_type"
                            disable
                            description="Select the Operating System (OS) for the image"
                          />
                          {formikProps.errors.os_type &&
                            formikProps.touched.os_type ? (
                            <div className="text-danger">
                              {formikProps.errors.os_type}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Image Name"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.bu_ami_name}
                            name="bu_ami_name"
                            disable
                            description="Enter a name for the image"
                          />
                          {formikProps.errors.bu_ami_name &&
                            formikProps.touched.bu_ami_name ? (
                            <div className="text-danger">
                              {formikProps.errors.bu_ami_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Pipeline Status"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.pipeline_status}
                            name="pipeline_status"
                            disable
                          />
                          {formikProps.errors.pipeline_status &&
                            formikProps.touched.pipeline_status ? (
                            <div className="text-danger">
                              {formikProps.errors.pipeline_status}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Last Run"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.pipeline_last_run}
                            name="pipeline_last_run"
                            disable
                          />
                          {formikProps.errors.pipeline_last_run &&
                            formikProps.touched.pipeline_last_run ? (
                            <div className="text-danger">
                              {formikProps.errors.pipeline_last_run}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Stack Status"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.stack_status}
                            name="stack_status"
                            disable
                          />
                          {formikProps.errors.stack_status &&
                            formikProps.touched.stack_status ? (
                            <div className="text-danger">
                              {formikProps.errors.stack_status}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Instance Type"
                            value={formikProps.values.instance_type}
                            onChange={(e) => formikProps.setFieldValue(`instance_type`, [e])}
                            name="instance_type"
                            option="instanceTypes"
                            multi
                            required
                            description="Select Instance Type"
                          />
                          {formikProps.errors.instance_type &&
                            formikProps.touched.instance_type ? (
                            <div className="text-danger">
                              {formikProps.errors.instance_type}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Root Volume Size(Gb)"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.root_volume_size}
                            name="root_volume_size"
                            description="Enter EBS root volume size for the image"
                          />
                          {formikProps.errors.root_volume_size &&
                            formikProps.touched.root_volume_size ? (
                            <div className="text-danger">
                              {formikProps.errors.root_volume_size}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="S3 Bucket"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.s3}
                            name="s3"
                            description="Enter the S3 bucket name where the script to be executed during image creation is stored"
                          />
                          {formikProps.errors.s3 &&
                            formikProps.touched.s3 ? (
                            <div className="text-danger">
                              {formikProps.errors.s3}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Script"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.script}
                            name="script"
                            description="Enter the name of the script to be used for image creation"
                          />
                          {formikProps.errors.script &&
                            formikProps.touched.script ? (
                            <div className="text-danger">
                              {formikProps.errors.script}
                            </div>
                          ) : null}
                        </div>
                        {
                          inputs?.map((item, index) => (
                            <div className="container-fluid" style={{ paddingLeft: 14 }} key={index}>
                              <div className="row">
                                <div className="col-12 col-xl-4 mb-4">
                                  <AutoCompleteWithChips
                                    label="Target Account ID"
                                    value={item.accounts}
                                    onChange={(e) => handleFormChange([e], index, "accounts")}
                                    name="target_account_ids"
                                    option="account"
                                    multi
                                    required
                                    description="Select the account ID for image sharing"
                                  />
                                </div>
                                <div className="col-12 col-xl-4 mb-4">
                                  <AutoCompleteWithChips
                                    label="Target Regions"
                                    value={item.regions}
                                    onChange={(e) => handleFormChange(e, index, "regions")}
                                    name="region"
                                    option="region"
                                    required
                                    description="Choose the regions for image sharing"
                                  />
                                </div>
                                <div style={{ marginTop: 30, marginLeft: 10 }}>
                                  <IconButton
                                    component="span"
                                    onClick={handleAddInput}
                                    style={{ color: "#424242", width: 40, height: 40 }}
                                    disableRipple
                                    size="large">
                                    <Add />
                                  </IconButton>
                                </div>
                                <div style={{ marginTop: 30, marginLeft: 15 }}>
                                  <IconButton
                                    component="span"
                                    onClick={() => handleRemoveInput(index)}
                                    style={{ color: "#424242", width: 40, height: 40 }}
                                    disableRipple
                                    size="large">
                                    <Clear />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                        <div>
                          <HOVButton
                            value="Update"
                            color="#fff"
                            backgroundColor="#008542"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            type="submit"
                            onClick={formikProps.handleSubmit}
                          />
                        </div>
                        <span className="mx-3"></span>
                        <div>
                          <HOVButton
                            value="Cancel"
                            color="#fff"
                            backgroundColor="#FF4E50"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            onClick={() => {
                              handleEditRequestClose()
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )
            :
            (
              <div style={{ height: "60vh" }}>
                <Loader />
              </div>
            )
        }
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default EditRequest;

const ValidationSchema = Yup.object().shape({
  bu_name: Yup.string().required('Please provide a business unit name.'),
  os_type: Yup.string().required('Please select OS type.'),
  s3: Yup.string().matches(/^[a-z][a-zA-Z0-9-.]*$/, "Please provide valid S3 bucket name").required('Please provide S3 bucket name.'),
  script: Yup.string().matches(/^[a-zA-z0-9].*/, "Please provide a valid script path").required('Please provide a script file name.'),
  bu_ami_name: Yup.string()
  .required('Please provide an AMI name.'),
  instance_type: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required()
      })
    ).min(1, 'Please select an instance type'),
  root_volume_size: Yup.string().matches(/^[0-9]*$/, "Root volume size can be number only").required('Please provide valid root volume size')
});