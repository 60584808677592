import React, { useState } from 'react';
import VPCTable from './VPCTable';
import AddVPC from './AddVPC';
import EditVPC from './EditVPC';
import HOVAlert from '../../components/hovAlert/HOVAlert';

const VPC = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [id, setId] = useState(0);

  const handleAddVPCOpen = () => {
    setCreate(true);
  };

  const handleAddVPCClose = () => {
    setCreate(false);
  };

  const handleEditVPCOpen = () => {
    setEdit(true);
  };

  const handleEditVPCClose = () => {
    setEdit(false);
  };

  const handleSnackClose = () => {
    setOpen(false);
  };


  return (
    <>
      {
        (
          create && <AddVPC
            handleAddVPCClose={handleAddVPCClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            create={create}
          />
        )
        ||
        (
          edit && <EditVPC
            handleEditVPCClose={handleEditVPCClose}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            edit={edit}
            id={id}
          />
        )
        ||
        (
          <VPCTable
            handleAddVPCOpen={handleAddVPCOpen}
            handleEditVPCOpen={handleEditVPCOpen}
            getVpc={setId}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            setVpcId={setId}
          />
        )
      }
      <HOVAlert
        open={open}
        onClose={handleSnackClose}
        severity={severity}
        message={message}
        duration={6000}
      />
    </>
  );
};

export default VPC;