import React, { useState, useEffect } from 'react';
import Topbar from '../../components/topbar/Topbar';
import { AxiosClient } from '../../lib/http';
import Loader from '../../components/loader/Loader';
import { Divider, Typography } from '@mui/material';
import "./BUGI.scss";

const BUGIUserScript = (props) => {
	const [attributeData, setAttributeData] = useState(null);
	const { biz_unit, os_type_bu_ami_name, script, s3_bucket, pipeline_last_run } = props.id;

	const getUserScript = async () => {
		try {
			const response = await AxiosClient.get(`script/${biz_unit}/${os_type_bu_ami_name}`);
			
			let scriptFile = response.data.script.split("\n");
			setAttributeData(scriptFile);
		}

		catch (error) {
			props.setOpen(true);
			props.setSeverity("error");
			if (error.response.data.message) {
				props.setMessage(error.response.data.message);
			}
			else {
				props.setMessage(error.message);
			}

			setTimeout(() => {
				props.handleScriptClose();
			}, 2000);
		}
	}

	useEffect(() => {
		let suscribe = true;
		if (suscribe) {
			getUserScript();
		}

		return () => {
			suscribe = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const values = {
		header: "GI Userdata Script",
		create: props.showScript,
		handleClose: props.handleScriptClose
	};

	return (
		<div className="page-table-container">
			<Topbar value={values} />
			<div
				className="form-container"
				style={{ height: 'fit-content', width: '90%' }}
			>
				{
					!attributeData ?
						(
							<div style={{ height: "60vh" }}>
								<Loader />
							</div>
						)
						:
						(
							<div className="s3-logs">
								<div className="script-header">
									<div className="header-item">
										<Typography style={{ marginRight: 10 }}>Business Unit:-</Typography>
										<Typography>{biz_unit}</Typography>
									</div>
									<Divider />
									<div className="header-item">
										<Typography style={{ marginRight: 10 }}>BU S3 Bucket:-</Typography>
										<Typography>{s3_bucket}</Typography>
									</div>
									<Divider />
									<div className="header-item">
										<Typography style={{ marginRight: 10 }}>Script Name:-</Typography>
										<Typography>{script}</Typography>
									</div>
									<Divider />
									<div className="header-item">
										<Typography style={{ marginRight: 10 }}>Pipeline Last Run:-</Typography>
										<Typography>{pipeline_last_run}</Typography>
									</div>
								</div>
								<Divider />
								<Typography style={{ padding: 2, margin: "10px 0" }}>Script Content</Typography>
								<Divider />
								{
									attributeData.length > 0 && attributeData.map((line, index) => (
										<Typography variant='body1' className="script" key={index}>{line}</Typography>
									))
								}
								<Divider />
							</div>
						)

				}
			</div>
		</div>
	);
};

export default BUGIUserScript;