import {
  Modal,
  Typography
} from '@mui/material';

import HOVButton from '../hovButton/HOVButton';

const modalStyle = {
  position: 'absolute',
  top: '55%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  background: '#fff',
  boxShadow: 24,
  padding: 20,
  borderRadius: '0.5rem'
};

const DeleteConfimation = ({ open, onClose, handleDelete, handleCancel }) => (
  <div>
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle}>
        <Typography id="modal-modal-title" variant="h5" gutterBottom style={{ font: "normal normal 500 Roboto" }}>
          Delete Confirmation
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="body1"
          style={{ marginTop: 5, font: "normal normal 500 Roboto" }}
          gutterBottom
        >
          Do you want to delete the record?
        </Typography>
        <HOVButton
          value="Delete"
          color="#fff"
          backgroundColor="#00358E"
          border="none"
          className="px-5 py-2 mt-2 mr-2"
          buttonWidth={140}
          custom
          type="submit"
          onClick={handleDelete}
        />
        <HOVButton
          value="Cancel"
          color="#fff"
          backgroundColor="#00358E"
          border="none"
          className="px-5 py-2 mt-2"
          buttonWidth={140}
          custom
          type="submit"
          onClick={handleCancel}
        />
      </div>
    </Modal>
  </div>
);

export default DeleteConfimation;