import React, { useContext } from 'react';
import accountsImg from '../../assets/icons/users.svg';
import vpcImg from '../../assets/icons/vpc.svg';
import businessImg from '../../assets/icons/business.svg';
import configRuleImg from '../../assets/icons/configRule.svg';
import configManagementImg from '../../assets/icons/configManagement.svg';
import goldenImg from '../../assets/icons/golden.svg';
import { Link } from 'react-router-dom';
import DrawerContext from '../../context/DrawerContext';

import "./DashboardCard.scss"

function DashboardCard({ title, index, route }) {
  const setDrawerState = useContext(DrawerContext).setDrawerState;

  const getCardImage = e => {
    switch (index) {
      case 0:
        return accountsImg
      case 1:
        return vpcImg
      case 2:
        return businessImg
      case 3:
        return configManagementImg
      case 4:
        return goldenImg
      case 5:
        return configRuleImg
      default:
        return;
    }
  }
  return (
    <Link to={route}>
      <div className="card-container">
        <div className="hov-card" onClick={() => setDrawerState(false)}>
          <div>
            <img src={getCardImage()} className="users-image" alt="users-img" />
          </div>
          <div className="title">{title}</div>
        </div>
      </div>
    </Link>
  )
}

export default DashboardCard
