import React from 'react'
import accountsImg from '../../assets/icons/users.svg';
import vpcImg from '../../assets/icons/vpc.svg';
import businessImg from '../../assets/icons/business.svg';
import configRuleImg from '../../assets/icons/configRule.svg';
import configManagementImg from '../../assets/icons/configManagement.svg';
import goldenImg from '../../assets/icons/golden.svg';
import homeImg from '../../assets/icons/home.svg';
import accountsActiveImg from '../../assets/icons/usersActive.svg';
import vpcActiveImg from '../../assets/icons/vpcActive.svg';
import businessActiveImg from '../../assets/icons/businessActive.svg';
import configRuleActiveImg from '../../assets/icons/configRuleActive.svg';
import configManagementActiveImg from '../../assets/icons/configManagementActive.svg';
import goldenActiveImg from '../../assets/icons/goldenActive.svg';
import homeActiveImg from '../../assets/icons/homeActive.svg';

function LHSimage({ index, id, isActive }) {
  const style = {
    height: '18px',
    width: 'auto'
  }
  const getLHSimages = e => {
    if (isActive) {
      switch (index) {
        case 0:
          return homeActiveImg
        case 1:
          return accountsActiveImg
        case 2:
          return vpcActiveImg
        case 3:
          return businessActiveImg
        case 4:
          return configManagementActiveImg
        case 5:
          return goldenActiveImg
        case 6:
          return configRuleActiveImg
        default:
          return;
      }
    } else
      switch (index) {
        case 0:
          return homeImg
        case 1:
          return accountsImg
        case 2:
          return vpcImg
        case 3:
          return businessImg
        case 4:
          return configManagementImg
        case 5:
          return goldenImg
        case 6:
          return configRuleImg
        default:
          return;
      }


  }

  return (
    <img src={getLHSimages()} className="drawer-icon" id={id} alt="drawer-icon" style={style} />
  )
}

export default LHSimage
