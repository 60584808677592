import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import './HOVButton.scss';

// greenish color
const successBtnColo = '#008542';

export const HOVButton = ({
  editable = false,
  value,
  border,
  className,
  onClick,
  backgroundColor = successBtnColo,
  color = 'white',
  custom = false,
  buttonWidth = "10rem",
  disable = false,
  id
}) => {
  const styles = {
    backgroundColor: custom ? backgroundColor : editable ? 'white' : '#00358E',
    color: custom ? color : editable ? 'black' : 'white',
    border: custom ? border : editable ? '1px solid #54585a' : 'none',
    width: buttonWidth
  };

  return (
    <Button
      variant="contained"
      className={`HOVButton ${className}`}
      style={styles}
      onClick={onClick}
      disabled={disable}
      id={id}
    >
      {value}
    </Button>
  );
};

Button.propTypes = {
  border: PropTypes.bool,
  value: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

HOVButton.defaultProps = {
  border: '1px solid #54585a',
  value: 'Air-Sandbox',
  textColor: 'black',
  backgroundColor: 'white',
};

export default HOVButton;