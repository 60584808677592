import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import Loader from '../../components/loader/Loader';
import useApiCalls from '../../hooks/useApiCalls';
import { BU_USER, SUPER_USER } from '../../roles';
import { GlobalContext } from '../../App';

const EditVPC = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const [attributeData, setAttributeData] = useState(null);
  const drawerState = useContext(DrawerContext).drawerState;
  const [update, setUpdate] = useState(false);
  const {
    getFormData,
    updateFormData,
    useMessage,
    useSeverity,
    useOpen,
    useAttributeData,
    useHttpStatus,
    useError
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleEditVPCClose,
    edit
  } = props;

  const { state, dispatch } = useContext(GlobalContext);

  // Initial call for form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getFormData(`vpc/${props.id}`);
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Setting form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setAttributeData(useAttributeData);
    }

    return () => {
      suscribe = false;
    }
  }, [useAttributeData]);


  // For showing any error while fetching form data
  useEffect(() => {
    if (useError) {
      setMessage(useMessage);
      setSeverity(useSeverity);
      setOpen(useOpen);

      setTimeout(() => {
        handleEditVPCClose();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useError, useMessage, useSeverity, useOpen]);

  // Checking for update success/failure
  useEffect(() => {
    if (update) {
      setMessage(useMessage);
      setSeverity(useSeverity);
      setOpen(useOpen);

      if (useHttpStatus === 201) {
        setTimeout(() => {
          setBackdrop(false);
          handleEditVPCClose();
        }, 2000);
      }
      else {
        setBackdrop(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMessage, useHttpStatus, useOpen]);

  const values = {
    header: "Edit VPC",
    edit: edit,
    handleClose: handleEditVPCClose
  };

  const isReadOnlYUser = (state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? false : true
  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        {
          attributeData ?
            (
              <Formik
                initialValues={{
                  vpcid: attributeData?.vpc_id,
                  domainId: attributeData?.domain,
                  appId: attributeData?.app_id,
                  ouPath: attributeData?.ou_path,
                  businessUnit: [{
                    label: attributeData?.biz_unit,
                    value: attributeData?.biz_unit
                  }],
                  env: [{
                    label: attributeData?.env,
                    value: attributeData?.env,
                  }],
                  region: [{
                    label: attributeData?.region,
                    value: attributeData?.region
                  }],
                  account_id: [{
                    label: attributeData?.account_id,
                    value: attributeData?.account_id
                  }]
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                  setBackdrop(true);
                  setUpdate(false);
                  try {
                    const keys = Object.keys(values);
                    const keysLength = keys.length;

                    let formatedValues = {
                      vpcid: '',
                      domainId: '',
                      appId: '',
                      ouPath: '',
                      businessUnit: '',
                      region: '',
                      account_id: '',
                      env: ''
                    };

                    // filtering only values and removing labels
                    const filteredValues = keys.map((key) => {
                      if (values[key] instanceof Array) {
                        return values[key].map(
                          (singleArrayValue) => singleArrayValue.value
                        );
                      }
                      return values[key];
                    });

                    // mapping filtered values to formatedValues
                    for (let index = 0; index < keysLength; ++index) {
                      let key = keys[index];
                      formatedValues[key] = filteredValues[index];
                    }

                    formatedValues['id'] = attributeData?.id;
                    formatedValues["account_id"] = formatedValues["account_id"][0];
                    formatedValues["region"] = formatedValues["region"][0];
                    formatedValues["env"] = formatedValues["env"][0];
                    formatedValues["businessUnit"] = formatedValues["businessUnit"][0];

                    // Account Id validation
                    const accountIdExp = new RegExp(/^[0-9]+$/)
                    if (formatedValues["account_id"].length < 12 || !accountIdExp.test(formatedValues["account_id"])) {
                      setOpen(true);
                      setMessage(`Please enter valid account id`);
                      setSeverity("error");
                      setBackdrop(false);
                      return;
                    }

                    updateFormData('vpc', formatedValues, 'SET_VPC_DATA', 'vpc');
                    setUpdate(true);
                  }
                  catch (error) {
                    setOpen(true);
                    setMessage(error.message);
                    setSeverity("error");
                    setBackdrop(false);
                  }
                }}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="VPC ID"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.vpcid}
                            name="vpcid"
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.vpcid && formikProps.touched.vpcid ? (
                            <div className="text-danger">
                              {formikProps.errors.vpcid}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Domain ID"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.domainId}
                            name="domainId"
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.domainId &&
                            formikProps.touched.domainId ? (
                            <div className="text-danger">
                              {formikProps.errors.domainId}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="App ID"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.appId}
                            name="appId"
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.appId && formikProps.touched.appId ? (
                            <div className="text-danger">
                              {formikProps.errors.appId}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="OU Path"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.ouPath}
                            name="ouPath"
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.ouPath && formikProps.touched.ouPath ? (
                            <div className="text-danger">
                              {formikProps.errors.ouPath}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Business Unit"
                            value={formikProps.values.businessUnit}
                            onChange={(e) => formikProps.setFieldValue(`businessUnit`, [e])}
                            name="businessUnit"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="bu"
                            multi
                            required
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.businessUnit &&
                            formikProps.touched.businessUnit ? (
                            <div className="text-danger">
                              {formikProps.errors.businessUnit}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Environment"
                            value={formikProps.values.env}
                            onChange={(e) => formikProps.setFieldValue(`env`, [e])}
                            name="env"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="env"
                            multi
                            required
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.env &&
                            formikProps.touched.env ? (
                            <div className="text-danger">
                              {formikProps.errors.env}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Region"
                            value={formikProps.values.region}
                            onChange={(e) => formikProps.setFieldValue(`region`, [e])}
                            name="region"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="region"
                            multi
                            required
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.region &&
                            formikProps.touched.region ? (
                            <div className="text-danger">
                              {formikProps.errors.region}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Account ID"
                            value={formikProps.values.account_id}
                            onChange={(e) => formikProps.setFieldValue(`account_id`, [e])}
                            name="account_id"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="account"
                            multi
                            required
                            disable={isReadOnlYUser}
                          />
                          {formikProps.errors.account_id &&
                            formikProps.touched.account_id ? (
                            <div className="text-danger">
                              {formikProps.errors.account_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                        <div>
                          {(state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? (
                            <HOVButton
                            value="Update"
                            color="#fff"
                            backgroundColor="#008542"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            type="submit"
                            onClick={formikProps.handleSubmit}
                          />
                          ):null}
                          
                        </div>
                        <span className="mx-3"></span>
                        <div>
                          <HOVButton
                            value="Cancel"
                            color="#fff"
                            backgroundColor="#FF4E50"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            onClick={() => {
                              handleEditVPCClose()
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )
            :
            <div style={{ height: "50vh" }}>
              <Loader />
            </div>
        }
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default EditVPC;

const ValidationSchema = Yup.object().shape({
  vpcid: Yup.string().required('Please provide a vpc id.'),
  domainId: Yup.string().required('Please provide a domain id.'),
  appId: Yup.string().required('Please provide a app id.'),
  ouPath: Yup.string().required('Please provide a ou path.'),
  businessUnit: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit.'),
  env: Yup.array().required('Please provide the environment name.'),
  region: Yup.array().required('Please provide the region.'),
  account_id: Yup.array().required('Please provide an account id.')
});
