import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 1000000000,
  crossDomain: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
});


client.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('hov_token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete config.headers.Authorization;
      delete client.defaults.headers.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const AxiosClient = client;
