import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import { GlobalContext } from '../../App';
import useApiCalls from '../../hooks/useApiCalls';

const AddConfigManagement = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const drawerState = useContext(DrawerContext).drawerState;
  const [add, setAdd] = useState(false);
  const { state } = useContext(GlobalContext);
  const {
    addRecord,
    useHttpStatus,
    useMessage,
    useOpen,
    useSeverity
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleAddConfigManagementClose,
    create
  } = props;


  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (add) {
        setOpen(useOpen);
        setMessage(useMessage);
        setSeverity(useSeverity);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleAddConfigManagementClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useHttpStatus, useMessage, useOpen]);

  const values = {
    header: "Add Config",
    create: create,
    handleClose: handleAddConfigManagementClose
  };

  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        <Formik
          initialValues={{
            businessUnit: state.bizUnits?.data?.length === 1 ? [{
              label: state.bizUnits?.data[0].BusinessUnit,
              value: state.bizUnits?.data[0].BusinessUnit
            }] : [],
            cfgManagementTool: '',
            cfg_mgt_master_url: '',
            region: '',
            validation_url: '',
            os_types: [],
            deployment_zones: [],
            env_types: []
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => {
            setBackdrop(true);
            setAdd(false);
            try {
              const keys = Object.keys(values);
              const keysLength = keys.length;

              let formatedValues = {
                businessUnit: '',
                cfg_mgt_master_url: '',
                os_types: [],
                deployment_zones: [],
                validation_url: '',
                env_types: [],
                region: '',
              };

              // filtering only values and removing labels
              const filteredValues = keys.map((key) => {
                if (values[key] instanceof Array) {
                  return values[key].map(
                    (singleArrayValue) => singleArrayValue.value
                  );
                }
                return values[key];
              });

              // mapping filtered values to formatedValues
              for (let index = 0; index < keysLength; ++index) {
                let key = keys[index];
                formatedValues[key] = filteredValues[index];
              }

              formatedValues["region"] = formatedValues["region"].toString();
              formatedValues["businessUnit"] = formatedValues["businessUnit"][0];

              addRecord('cfgmanagement', formatedValues, 'SET_CONFIG_MANAGEMENT_DATA');
              setAdd(true);
            }
            catch (error) {
              setOpen(true);
              setMessage(error.message);
              setSeverity("error");
              setBackdrop(false);
            }
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Business Unit"
                      value={formikProps.values.businessUnit}
                      onChange={(e) => formikProps.setFieldValue(`businessUnit`, [e])}
                      name="businessUnit"
                      option="bu"
                      multi
                      required
                    />
                    {formikProps.errors.businessUnit &&
                      formikProps.touched.businessUnit ? (
                      <div className="text-danger">
                        {formikProps.errors.businessUnit}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Config Management Tool"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.cfgManagementTool}
                      name="cfgManagementTool"
                    />
                    {formikProps.errors.cfgManagementTool &&
                      formikProps.touched.cfgManagementTool ? (
                      <div className="text-danger">
                        {formikProps.errors.cfgManagementTool}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Validation URL"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.validation_url}
                      name="validation_url"
                    />
                    {formikProps.errors.validation_url &&
                      formikProps.touched.validation_url ? (
                      <div className="text-danger">
                        {formikProps.errors.validation_url}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <HOVInput
                      label="Master URL"
                      required
                      onChangeProp={formikProps.handleChange}
                      onBlurProp={formikProps.handleBlur}
                      valueProp={formikProps.values.cfg_mgt_master_url}
                      name="cfg_mgt_master_url"
                    />
                    {formikProps.errors.cfg_mgt_master_url &&
                      formikProps.touched.cfg_mgt_master_url ? (
                      <div className="text-danger">
                        {formikProps.errors.cfg_mgt_master_url}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="OS Type(s):"
                      value={formikProps.values.os_types}
                      onChange={(e) => formikProps.setFieldValue(`os_types`, e)}
                      name="os_types"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="os"
                    />
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Deployment Zone(s):"
                      value={formikProps.values.deployment_zones}
                      onChange={(e) => formikProps.setFieldValue(`deployment_zones`, e)}
                      name="deployment_zones"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="deployment"
                    />
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Environment(s)"
                      value={formikProps.values.env_types}
                      onChange={(e) => formikProps.setFieldValue(`env_types`, e)}
                      name="env_types"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="env"
                    />
                    {formikProps.errors.env_types &&
                      formikProps.touched.env_types ? (
                      <div className="text-danger">
                        {formikProps.errors.env_types}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 mb-4">
                    <AutoCompleteWithChips
                      label="Region"
                      value={formikProps.values.region}
                      onChange={(e) => formikProps.setFieldValue(`region`, [e])}
                      name="region"
                      form={formikProps}
                      xstyle={controlsStyle}
                      option="region"
                      multi
                      required
                    />
                    {formikProps.errors.region &&
                      formikProps.touched.region ? (
                      <div className="text-danger">
                        {formikProps.errors.region}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                  <div>
                    <HOVButton
                      value="Add"
                      color="#fff"
                      backgroundColor="#008542"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      type="submit"
                      onClick={formikProps.handleSubmit}
                    />
                  </div>
                  <span className="mx-3"></span>
                  <div>
                    <HOVButton
                      value="Cancel"
                      color="#fff"
                      backgroundColor="#FF4E50"
                      border="none"
                      className="px-5 py-2"
                      buttonWidth={140}
                      custom
                      onClick={() => {
                        handleAddConfigManagementClose();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default AddConfigManagement;

const ValidationSchema = Yup.object().shape({
  businessUnit: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit.'),
  cfgManagementTool: Yup.string().required(
    'Please provide config management tool name.'
  ),
  cfg_mgt_master_url: Yup.string().required('Please provide master url.'),
  region: Yup.array().required('Please provide a region.'),
  validation_url: Yup.string().required('Please provide a validation url.')
});
