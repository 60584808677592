import React, { useState, useContext  } from 'react';
import AccountsTable from './AccountsTable';
import AddAccount from './AddAccount';
import EditAccount from './EditAccount';
import HOVAlert from '../../components/hovAlert/HOVAlert';
import { GlobalContext } from '../../App';

const Accounts = (props) => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [id, setId] = useState(0);

  const handleAddAccountOpen = () => {
    setCreate(true);
  };

  const handleAddAccountClose = () => {
    setCreate(false);
  };

  const handleEditAccountOpen = () => {
    setEdit(true);
  };

  const handleEditAccountClose = () => {
    setEdit(false);
  };

  const handleSnackClose = () => {
    setOpen(false);
  };

  const {state, dispatch} = useContext(GlobalContext)


  return (
    <>
      {
        (create && <AddAccount
          handleAddAccountClose={handleAddAccountClose}
          setMessage={setMessage}
          setSeverity={setSeverity}
          setOpen={setOpen}
          create={create}
        />)
        ||
        (edit && <EditAccount
          handleEditAccountClose={handleEditAccountClose}
          setMessage={setMessage}
          setSeverity={setSeverity}
          setOpen={setOpen}
          edit={edit}
          id={id}
        />)
        ||
        (<AccountsTable
          handleAddAccountOpen={handleAddAccountOpen}
          handleEditAccountOpen={handleEditAccountOpen}
          getAccountId={setId}
          setMessage={setMessage}
          setSeverity={setSeverity}
          setOpen={setOpen}
        />)
      }
      <HOVAlert
        open={open}
        onClose={handleSnackClose}
        severity={severity}
        message={message}
        duration={6000}
      />
    </>
  );
};

export default Accounts;