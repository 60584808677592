import React, { useState } from 'react';
import BusinessUnitTable from './BusinessUnitTable';
import AddBusinessUnit from './AddBusinessUnit';
import EditBusinessUnit from './EditBusinessUnit';
import HOVAlert from '../../components/hovAlert/HOVAlert';

const BusinessUnit = () => {
	const [create, setCreate] = useState(false);
	const [edit, setEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("success");
	const [id, setId] = useState(0);

	const handleAddBusinessUnitOpen = () => {
		setCreate(true);
	};

	const handleAddBusinessUnitClose = () => {
		setCreate(false);
	};

	const handleEditBusinessUnitOpen = () => {
		setEdit(true);
	};

	const handleEditBusinessUnitClose = () => {
		setEdit(false);
	};

	const handleSnackClose = () => {
		setOpen(false);
	};


	return (
		<>
			{
				(
					create && <AddBusinessUnit
						handleAddBusinessUnitClose={handleAddBusinessUnitClose}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						create={create}
					/>
				)
				||
				(
					edit && <EditBusinessUnit
						handleEditBusinessUnitClose={handleEditBusinessUnitClose}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						edit={edit}
						id={id}
					/>
				)
				||
				(
					<BusinessUnitTable 
						handleAddBusinessUnitOpen={handleAddBusinessUnitOpen}
						handleEditBusinessUnitOpen={handleEditBusinessUnitOpen}
						getVpc={setId}
						setMessage={setMessage}
						setSeverity={setSeverity}
						setOpen={setOpen}
						setBUId={setId}
					/>
				)
			}
			<HOVAlert 
				open={open}
				onClose={handleSnackClose}
				severity={severity}
				message={message}
				duration={6000}
			/>
		</>
	);
};

export default BusinessUnit;