import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './HOVInput.scss';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';

export const HOVInput = ({
  label,
  name,
  textColor = '#fff',
  backgroundColor = '#06357A',
  type,
  placeholder,
  className,
  classNameMain,
  required,
  onChangeProp,
  valueProp,
  disable,
  description
}) => {
  return (
    <Form.Group
      className={`relative ${classNameMain}`}
      controlId={name}
    >
      <Form.Label >
        {label} {required ? (
          <span className="text-danger font-weight-medium">*</span>
        ) : null}
        {
          description ? (
            <Tooltip title={description} placement="top">
              <IconButton size="small" style={{ color: "#616161" }} disableRipple>
                <InfoOutlined color='inherit' fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )
            : null
        }
      </Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        className={`input-primary ${className}`}
        onChange={onChangeProp}
        defaultValue={valueProp}
        disabled={disable}
      />
    </Form.Group>
  );
};

Form.propTypes = {
  border: PropTypes.bool,
  value: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.string,
  calendar: PropTypes.source,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  className1: PropTypes.string,
  onClick: PropTypes.func,
};

HOVInput.defaultProps = {
  border: '1px solid #9e9e9e',
  value: 'Air-Sandbox',
  textColor: 'black',
  backgroundColor: 'white',
};

export default HOVInput;