import React from 'react';
import DashboardCard from './DashboardCard';
import './Dashboard.scss';

// todo: remove
import dashMock from './dashMock';
// import { pagesData, GlobalContext } from '../../App';

function Dashboard() {
  // const { state } = useContext(GlobalContext);
  // const currentUserRoles = state.user.roles;
  return (
    <div className="dashboard-cards-container">
      {dashMock.map((card, index) => {
        // const roles = pagesData.find((page) => page.title === card.title.toUpperCase())?.roles;
        // const hasValidPermission = grantPermission(currentUserRoles, roles);
        // if (hasValidPermission)
        return (
          <DashboardCard
            title={card.title}
            key={`dash_card_id_${index}`}
            index={index}
            route={card.route}
          />
        );
      })}
    </div>
  );
}

export default Dashboard;
