import React from 'react'
import { CircularProgress } from '@mui/material';
function Loader() {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader
