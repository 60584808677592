import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop,
  Typography,
  Divider
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import HOVCheckbox from '../../components/hovCheckbox/HOVCheckbox';
import './Account.scss';
import DrawerContext from '../../context/DrawerContext';
import Loader from '../../components/loader/Loader';
import useApiCalls from '../../hooks/useApiCalls';
import { GlobalContext } from '../../App';
import { BU_USER, SUPER_USER } from '../../roles';


const EditAccount = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdrop, setBackdrop] = useState(false);
  const [attributeData, setAttributeData] = useState(null);
  const [disable, setDisable] = useState(true);
  const { state } = useContext(GlobalContext);
  const drawerState = useContext(DrawerContext).drawerState;
  const [update, setUpdate] = useState(false);
  const {
    getFormData,
    updateFormData,
    useMessage,
    useSeverity,
    useOpen,
    useAttributeData,
    useHttpStatus,
    useError
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleEditAccountClose,
    edit
  } = props;

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getFormData(`accountattributes/${props.id}`);
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setting form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setAttributeData(useAttributeData);
    }

    return () => {
      suscribe = false;
    }
  }, [useAttributeData]);


  // For showing any error while fetching form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (useError) {
        setMessage(useMessage);
        setSeverity(useSeverity);
        setOpen(useOpen);

        setTimeout(() => {
          handleEditAccountClose();
        }, 2000);
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useError, useMessage, useSeverity, useOpen]);

  // Checking for update success/failure
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (update) {
        setMessage(useMessage);
        setSeverity(useSeverity);
        setOpen(useOpen);

        if (useHttpStatus === 201) {
          setTimeout(() => {
            setBackdrop(false);
            handleEditAccountClose();
          }, 2000);
        }
        else {
          setBackdrop(false);
        }
      }
    }

    return () => {
      suscribe = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMessage, useHttpStatus, useOpen]);

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (state.user.roles?.includes(process.env.REACT_APP_SUPER_ACCESS_GROUP)) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }

    return () => {
      suscribe = false;
    }

  }, [state.user]);

  const values = {
    header: "Edit Account Attributes",
    edit: edit,
    handleClose: handleEditAccountClose
  };
  const isReadOnlyUser = (state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? false : true
  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ minHeight: '75vh', width: '90%' }}
      >
        {
          attributeData ?
            (
              <Formik
                initialValues={{
                  short_name: attributeData?.data[0]?.short_name,
                  account_owner: attributeData?.data[0]?.account_owner,
                  account_id: attributeData?.data[0]?.account_id,
                  account_name: attributeData?.data[0]?.account_name,
                  account_env: [{
                    label: attributeData?.data[0]?.account_env,
                    value: attributeData?.data[0]?.account_env
                  }],
                  vertical: [{
                    label: attributeData?.data[0]?.vertical,
                    value: attributeData?.data[0]?.vertical
                  }],
                  okta_url: attributeData?.data[0]?.okta_url,
                  division: attributeData?.data[0]?.division,
                  root_email: attributeData?.data[0]?.root_email,
                  patch_management: [{
                    label: attributeData?.data[0]?.patch_management,
                    value: attributeData?.data[0]?.patch_management
                  }],
                  account_type: [{
                    label: attributeData?.data[0]?.account_type,
                    value: attributeData?.data[0]?.account_type
                  }],
                  business_unit: [{
                    label: attributeData?.data[0]?.business_unit,
                    value: attributeData?.data[0]?.business_unit
                  }],
                  costOptimizationEmail: attributeData?.data[0]?.cost_optimization?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  owca: attributeData?.data[0]?.owca?.map((email) => ({
                    label: email,
                    value: email,
                  })),
                  servicesHealthEmail: attributeData?.data[0]?.service_health,
                  configAlertEmail: attributeData?.data[0]?.config_alerts_email?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  configAlertEmailCc: attributeData?.data[0]?.config_alerts_email_cc?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  accessApprovers: attributeData?.data[0]?.access_approvers?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  accessReportReciepent: attributeData?.data[0]?.access_report_recipients?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  accessReportFrequency: [{
                    label: attributeData?.data[0]?.access_report_frequency, value: attributeData?.data[0]?.access_report_frequency
                  }],
                  accessReportSubscription: attributeData?.data[0]?.access_report_subscription,
                  businessContacts: attributeData?.data[0]?.business_contacts?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  technicalContacts: attributeData?.data[0]?.technical_contacts?.map(
                    (email) => ({ label: email, value: email })
                  ),
                  ou_name: [{ label: attributeData?.data[0]?.ou_name, value: attributeData?.data[0]?.ou_name }],
                  ou_id: attributeData?.data[0]?.ou_id,
                  regions: attributeData?.data[0]?.regions.map((region) => ({ label: region, value: region })),
                  account_status: [{ label: attributeData?.data[0]?.account_status, value: attributeData?.data[0]?.account_status }],
                  account_create_time: attributeData?.data[0]?.account_create_time,
                  business_unit_alignment: [{
                    label: attributeData?.data[0]?.business_unit_alignment,
                    value: attributeData?.data[0]?.business_unit_alignment
                  }],
                  okta_profile_name: attributeData?.data[0]?.okta_profile_name,
                  subgroup_name: attributeData?.data[0]?.subgroup_name,
                  bu_gi: attributeData?.data[0]?.bu_gi,
                  default: [{
                    label: attributeData?.data[0]?.default,
                    value: attributeData?.data[0]?.default
                  }],
                  gi: attributeData?.data[0]?.gi,
                  gi_cleanup: attributeData?.data[0]?.gi_cleanup,
                  gi_v2: attributeData?.data[0]?.gi_v2,
                  gi_v3: attributeData?.data[0]?.gi_v3,
                  lookup_functions: attributeData?.data[0]?.lookup_functions,
                  workspaces_cost_optimizer: attributeData?.data[0]?.workspaces_cost_optimizer,
                  cfn_hup_interval: attributeData?.data[0]?.cfn_hup_interval,
                  cloud_inventory: attributeData?.data[0]?.cloud_inventory,
                  shd: attributeData?.data[0]?.shd,
                  phd: attributeData?.data[0]?.phd,
                  ri_notifier: attributeData?.data[0]?.ri_notifier,
                  gi_monthly_pinned_release: attributeData?.data[0]?.gi_monthly_pinned_release
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                  setBackdrop(true);
                  setUpdate(false);
                  try {
                    const keys = Object.keys(values);
                    const keysLength = keys.length;

                    let formatedValues = {
                      account_id: attributeData?.data[0]?.account_id,
                      account_name: '',
                      account_owner: '',
                      account_env: '',
                      account_type: '',
                      vertical: '',
                      business_unit: '',
                      division: '',
                      root_email: '',
                      patch_management: '',
                      okta_url: '',
                      costOptimizationEmail: '',
                      owca: '',
                      servicesHealthEmail: '',
                      configAlertEmail: '',
                      configAlertEmailCc: '',
                      accessApprovers: '',
                      accessReportReciepent: '',
                      accessReportFrequency: '',
                      accessReportSubscription: false,
                      businessContacts: '',
                      technicalContacts: '',
                      ou_name: [],
                      regions: [],
                      account_status: [],
                      business_unit_alignment: '',
                      okta_profile_name: '',
                      subgroup_name: '',
                      bu_gi: false,
                      default: [],
                      gi: false,
                      gi_cleanup: false,
                      gi_v2: false,
                      gi_v3: false,
                      lookup_functions: false,
                      workspaces_cost_optimizer: false,
                      cfn_hup_interval: '',
                      cloud_inventory: false,
                      shd: false,
                      phd: false,
                      ri_notifier: false,
                      gi_monthly_pinned_release: false
                    };

                    let emailValidationList = [
                      "owca",
                      "costOptimizationEmail",
                      "configAlertEmail",
                      "configAlertEmailCc",
                      "accessReportReciepent"
                    ];

                    let emailValidationListMap = {
                      owca: 'OWCA',
                      costOptimizationEmail: 'Cost Optimization Email',
                      configAlertEmail: 'Config Alerts Email',
                      configAlertEmailCc: 'Config Alerts Email CC',
                      accessReportReciepent: 'Access Report Recipients'
                    }

                    // filtering only values and removing labels
                    const filteredValues = keys.map((key) => {
                      if (key && values[key] instanceof Array) {
                        return values[key].map(
                          (singleArrayValue) => singleArrayValue.value
                        );
                      }
                      return values[key];
                    });

                    // mapping filtered values to formatedValues
                    for (let index = 0; index < keysLength; ++index) {
                      let key = keys[index];
                      if(key==="account_owner"){
                        formatedValues[key] = filteredValues[index]?.toLowerCase();
                      }
                      else if(key === "businessContacts" || key === "accessApprovers" || key === "technicalContacts"  ){
                        formatedValues[key] = filteredValues[index]?.map((_item)=>_item?.toLowerCase())
                      }
                      else{
                        formatedValues[key] = filteredValues[index];
                      }
                    }

                    // Email validation
                    const emailValidationExp = new RegExp(/^#?[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);

                    for (let index = 0; index < emailValidationList.length; index++) {
                      if (formatedValues[emailValidationList[index]]) {
                        for (let i = 0; i < formatedValues[emailValidationList[index]].length; i++) {
                          if (!emailValidationExp.test(formatedValues[emailValidationList[index]][i])) {
                            setOpen(true);
                            setMessage(`Please enter valid email id for ${emailValidationListMap[emailValidationList[index]]}`);
                            setSeverity("error");
                            setBackdrop(false);
                            return;
                          }
                        }
                      }
                    }

                    // For dropdown inputes that accepts single value.
                    formatedValues["business_unit"] = formatedValues["business_unit"][0];
                    formatedValues["accessReportFrequency"] = formatedValues["accessReportFrequency"][0];
                    formatedValues["account_status"] = formatedValues["account_status"][0];
                    formatedValues["default"] = formatedValues["default"][0];
                    formatedValues["ou_name"] = formatedValues["ou_name"][0];
                    formatedValues["account_env"] = formatedValues["account_env"][0];
                    formatedValues["vertical"] = formatedValues["vertical"][0];
                    formatedValues["account_type"] = formatedValues["account_type"][0];
                    formatedValues["business_unit_alignment"] = formatedValues["business_unit_alignment"][0];
                    formatedValues["patch_management"] = formatedValues["patch_management"][0];

                    // If no option is selected for default GI version
                    if (formatedValues["default"] === '') {
                      formatedValues["default"] = "gi_v3";
                    }

                    let modules = {
                      account_settings: {
                        cfn_hup_interval: formatedValues["cfn_hup_interval"],
                        default: formatedValues["default"],
                        gi_v2: formatedValues["gi_v2"],
                        gi_v3: formatedValues["gi_v3"],
                        gi_monthly_pinned_release: formatedValues["gi_monthly_pinned_release"]
                      },
                      bu_gi: formatedValues["bu_gi"],
                      cloud_inventory: formatedValues["cloud_inventory"],
                      gi: formatedValues["gi"],
                      gi_cleanup: formatedValues["gi_cleanup"],
                      lookup_functions: formatedValues["lookup_functions"],
                      workspaces_cost_optimizer: formatedValues["workspaces_cost_optimizer"],
                      shd: formatedValues["shd"],
                      phd: formatedValues["phd"],
                      ri_notifier: formatedValues["ri_notifier"]
                    };

                    formatedValues["modules"] = modules;

                    updateFormData('accountattributes', formatedValues, 'SET_ACCOUNT_DATA', 'accountattributes');
                    setUpdate(true);
                  }

                  catch (error) {
                    setBackdrop(false);
                    setOpen(true);
                    setMessage(error.message);
                    setSeverity("error");
                  }
                }}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Short Name"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.short_name}
                            name="short_name"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("short_name") || isReadOnlyUser}
                          />
                          {formikProps.errors.short_name &&
                            formikProps.touched.short_name && !disable ? (
                            <div className="text-danger">
                              {formikProps.errors.short_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Account Owner"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.account_owner}
                            name="account_owner"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("account_owner")||isReadOnlyUser}
                          />
                          {formikProps.errors.account_owner &&
                            formikProps.touched.account_owner ? (
                            <div className="text-danger">
                              {formikProps.errors.account_owner}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Vertical"
                            value={formikProps.values.vertical}
                            onChange={(e) => formikProps.setFieldValue(`vertical`, [e])}
                            name="vertical"
                            option={"option_vertical"}
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={!attributeData?.editable.includes("vertical")||isReadOnlyUser}
                          />
                          {formikProps.errors.vertical &&
                            formikProps.touched.vertical ? (
                            <div className="text-danger">
                              {formikProps.errors.vertical}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Okta URL"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.okta_url}
                            name="okta_url"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("okta_url")||isReadOnlyUser}
                          />
                          {formikProps.errors.okta_url &&
                            formikProps.touched.okta_url ? (
                            <div className="text-danger">
                              {formikProps.errors.okta_url}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Account ID"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.account_id}
                            name="account_id"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("account_id")||isReadOnlyUser}
                          />
                          {formikProps.errors.account_id &&
                            formikProps.touched.account_id ? (
                            <div className="text-danger">
                              {formikProps.errors.account_id}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Account Environment"
                            value={formikProps.values.account_env}
                            onChange={(e) => formikProps.setFieldValue(`account_env`, [e])}
                            name="account_env"
                            option={"option_env"}
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={!attributeData?.editable.includes("account_env")||isReadOnlyUser}
                          />
                          {formikProps.errors.account_env &&
                            formikProps.touched.account_env ? (
                            <div className="text-danger">
                              {formikProps.errors.account_env}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Business Unit"
                            value={formikProps.values.business_unit}
                            onChange={(e) => formikProps.setFieldValue(`business_unit`, [e])}
                            name="business_unit"
                            option="bu"
                            xstyle={controlsStyle}
                            multi
                            required
                            disable={!attributeData?.editable.includes("business_unit")||isReadOnlyUser}
                          />
                          {formikProps.errors.business_unit &&
                            formikProps.touched.business_unit ? (
                            <div className="text-danger">
                              {formikProps.errors.business_unit}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Root Email"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.root_email}
                            name="root_email"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("root_email")||isReadOnlyUser}
                          />
                          {formikProps.errors.root_email &&
                            formikProps.touched.root_email ? (
                            <div className="text-danger">
                              {formikProps.errors.root_email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Account Name"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.account_name}
                            name="account_name"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={true}
                          />
                          {formikProps.errors.account_name &&
                            formikProps.touched.account_name ? (
                            <div className="text-danger">
                              {formikProps.errors.account_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Account Type"
                            value={formikProps.values.account_type}
                            onChange={(e) => formikProps.setFieldValue(`account_type`, [e])}
                            name="account_type"
                            option={"option_account_type"}
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={!attributeData?.editable.includes("account_type")||isReadOnlyUser}
                          />
                          {formikProps.errors.account_type &&
                            formikProps.touched.account_type ? (
                            <div className="text-danger">
                              {formikProps.errors.account_type}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Patch Management"
                            value={formikProps.values.patch_management}
                            onChange={(e) => formikProps.setFieldValue(`patch_management`, [e])}
                            name="patch_management"
                            option={"option_patch_management"}
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={!attributeData?.editable.includes("patch_management")||isReadOnlyUser}
                          />
                          {formikProps.errors.patch_management &&
                            formikProps.touched.patch_management ? (
                            <div className="text-danger">
                              {formikProps.errors.patch_management}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Division"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.division}
                            name="division"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("division")||isReadOnlyUser}
                          />
                          {formikProps.errors.division &&
                            formikProps.touched.division ? (
                            <div className="text-danger">
                              {formikProps.errors.division}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Cost Optimization Notification Email(s)"
                            value={formikProps.values.costOptimizationEmail}
                            onChange={(e) => formikProps.setFieldValue(`costOptimizationEmail`, e)}
                            name="costOptimizationEmail"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="email"
                            required
                            disable={!attributeData?.editable.includes("cost_optimization")||isReadOnlyUser}
                          />
                          {formikProps.errors.costOptimizationEmail &&
                            formikProps.touched.costOptimizationEmail ? (
                            <div className="text-danger">
                              {formikProps.errors.costOptimizationEmail}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="OWCA:"
                            value={formikProps.values.owca}
                            onChange={(e) => formikProps.setFieldValue(`owca`, e)}
                            name="owca"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="email"
                            disable={!attributeData?.editable.includes("owca")||isReadOnlyUser}
                          />
                          {formikProps.errors.owca &&
                            formikProps.touched.owca ? (
                            <div className="text-danger">
                              {formikProps.errors.owca}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Services Health Notification Email"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.servicesHealthEmail}
                            name="servicesHealthEmail"
                            form={formikProps}
                            xstyle={controlsStyle}
                            disable={!attributeData?.editable.includes("service_health")||isReadOnlyUser}
                          />
                          {formikProps.errors.servicesHealthEmail &&
                            formikProps.touched.servicesHealthEmail ? (
                            <div className="text-danger">
                              {formikProps.errors.servicesHealthEmail}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Config Alerts Email"
                            value={formikProps.values.configAlertEmail}
                            onChange={(e) =>
                              formikProps.setFieldValue(`configAlertEmail`, e)
                            }
                            name="configAlertEmail"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="email"
                            disable={!attributeData?.editable.includes("config_alerts_email")||isReadOnlyUser}
                          />
                          {formikProps.errors.configAlertEmail &&
                            formikProps.touched.configAlertEmail ? (
                            <div className="text-danger">
                              {formikProps.errors.configAlertEmail}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Config Alerts Email Cc"
                            value={formikProps.values.configAlertEmailCc}
                            onChange={(e) =>
                              formikProps.setFieldValue(`configAlertEmailCc`, e)
                            }
                            name="configAlertEmailCc"
                            form={formikProps}
                            xstyle={controlsStyle}
                            disable={!attributeData?.editable.includes("config_alerts_email_cc")||isReadOnlyUser}
                          />
                          {formikProps.errors.configAlertEmailCc &&
                            formikProps.touched.configAlertEmailCc ? (
                            <div className="text-danger">
                              {formikProps.errors.configAlertEmailCc}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Access Approvers"
                            value={formikProps.values.accessApprovers}
                            onChange={(e) =>
                              formikProps.setFieldValue(`accessApprovers`, e)
                            }
                            name="accessApprovers"
                            form={formikProps}
                            xstyle={controlsStyle}
                            disable={!attributeData?.editable.includes("access_approvers")||isReadOnlyUser}
                          />
                          {formikProps.errors.accessApprovers &&
                            formikProps.touched.accessApprovers ? (
                            <div className="text-danger">
                              {formikProps.errors.accessApprovers}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Access Report Recipients"
                            value={formikProps.values.accessReportReciepent}
                            onChange={(e) =>
                              formikProps.setFieldValue(`accessReportReciepent`, e)
                            }
                            name="accessReportReciepent"
                            form={formikProps}
                            xstyle={controlsStyle}
                            disable={!attributeData?.editable.includes("access_report_recipients")||isReadOnlyUser}
                          />
                          {formikProps.errors.accessReportReciepent &&
                            formikProps.touched.accessReportReciepent ? (
                            <div className="text-danger">
                              {formikProps.errors.accessReportReciepent}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Access Report Frequency:"
                            required
                            value={formikProps.values.accessReportFrequency}
                            onChange={(e) =>
                              formikProps.setFieldValue(`accessReportFrequency`, [e])
                            }
                            name="accessReportFrequency"
                            form={formikProps}
                            xstyle={controlsStyle}
                            multi
                            option="frequency"
                            disable={!attributeData?.editable.includes("access_report_frequency")||isReadOnlyUser}
                          />
                          {formikProps.errors.accessReportFrequency &&
                            formikProps.touched.accessReportFrequency ? (
                            <div className="text-danger">
                              {formikProps.errors.accessReportFrequency}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4 mt-4">
                          <HOVCheckbox
                            value={formikProps.values.accessReportSubscription}
                            onChange={formikProps.handleChange}
                            name="accessReportSubscription"
                            label="Access Report Subscription"
                            disable={!attributeData?.editable.includes("access_report_subscription")||isReadOnlyUser}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Business Contacts"
                            value={formikProps.values.businessContacts}
                            onChange={(e) =>
                              formikProps.setFieldValue(`businessContacts`, e)
                            }
                            name="businessContacts"
                            form={formikProps}
                            disable={!attributeData?.editable.includes("business_contacts")||isReadOnlyUser}
                          />
                          {formikProps.errors.businessContacts &&
                            formikProps.touched.businessContacts ? (
                            <div className="text-danger">
                              {formikProps.errors.businessContacts}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Technical Contacts"
                            value={formikProps.values.technicalContacts}
                            onChange={(e) =>
                              formikProps.setFieldValue(`technicalContacts`, e)
                            }
                            name="technicalContacts"
                            form={formikProps}
                            disable={!attributeData?.editable.includes("technical_contacts")||isReadOnlyUser}
                          />
                          {formikProps.errors.technicalContacts &&
                            formikProps.touched.technicalContacts ? (
                            <div className="text-danger">
                              {formikProps.errors.technicalContacts}
                            </div>
                          ) : null}
                        </div>
                        <div>
                        </div>
                        <Divider className="col-12 col-xl-11 mb-2" />
                        <div className="col-12 col-xl-12 mb-3">
                          <Typography variant='h6'>Modules</Typography>
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="OU Name"
                            value={formikProps.values.ou_name}
                            onChange={(e) =>
                              formikProps.setFieldValue(`ou_name`, [e])
                            }
                            name="ou_name"
                            form={formikProps}
                            option="ou"
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={isReadOnlyUser}
                          />
                          {formikProps.errors.ou_name &&
                            formikProps.touched.ou_name ? (
                            <div className="text-danger">
                              {formikProps.errors.ou_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="OU ID"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.ou_id}
                            name="ou_id"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("ou_id")||isReadOnlyUser}
                          />
                          {formikProps.errors.ou_id &&
                            formikProps.touched.ou_id ? (
                            <div className="text-danger">
                              {formikProps.errors.ou_id}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Account Status"
                            required
                            value={formikProps.values.account_status}
                            onChange={(e) =>
                              formikProps.setFieldValue(`account_status`, [e])
                            }
                            name="account_status"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="accountStatus"
                            multi
                            disable={isReadOnlyUser}
                          />
                          {formikProps.errors.account_status &&
                            formikProps.touched.account_status ? (
                            <div className="text-danger">
                              {formikProps.errors.account_status}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Account Creation Time"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.account_create_time}
                            name="account_create_time"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("account_create_time")||isReadOnlyUser}
                          />
                          {formikProps.errors.account_create_time &&
                            formikProps.touched.account_create_time ? (
                            <div className="text-danger">
                              {formikProps.errors.account_create_time}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                           <AutoCompleteWithChips
                            label="Business Unit Alignment"
                            value={formikProps.values.business_unit_alignment}
                            onChange={(e) => formikProps.setFieldValue(`business_unit_alignment`, [e])}
                            name="business_unit_alignment"
                            option={"option_bua"}
                            xstyle={controlsStyle}
                            required
                            multi
                            disable={!attributeData?.editable.includes("business_unit_alignment")||isReadOnlyUser}
                          />
                          {formikProps.errors.business_unit_alignment &&
                            formikProps.touched.business_unit_alignment ? (
                            <div className="text-danger">
                              {formikProps.errors.business_unit_alignment}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            required
                            label="Okta Profile Name"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.okta_profile_name}
                            name="okta_profile_name"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("okta_profile_name")||isReadOnlyUser}
                          />
                          {formikProps.errors.okta_profile_name &&
                            formikProps.touched.okta_profile_name ? (
                            <div className="text-danger">
                              {formikProps.errors.okta_profile_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-1">
                          <HOVInput
                            label="Subgroup Name"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.subgroup_name}
                            name="subgroup_name"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            disable={!attributeData?.editable.includes("subgroup_name")||isReadOnlyUser}
                          />
                          {formikProps.errors.subgroup_name &&
                            formikProps.touched.subgroup_name ? (
                            <div className="text-danger">
                              {formikProps.errors.subgroup_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-1">
                          <AutoCompleteWithChips
                            label="Regions"
                            value={formikProps.values.regions}
                            onChange={(e) =>
                              formikProps.setFieldValue(`regions`, e)
                            }
                            name="regions"
                            form={formikProps}
                            option="region"
                            disable={!attributeData?.editable.includes("regions")||isReadOnlyUser}
                            required
                          />
                          {formikProps.errors.regions &&
                            formikProps.touched.regions ? (
                            <div className="text-danger">
                              {formikProps.errors.regions}
                            </div>
                          ) : null}
                        </div>
                        <Divider className="col-12 col-xl-11 mb-2" />
                        <div className="col-12 col-xl-12 mb-3">
                          <Typography variant='h6'>Select HOV Features</Typography>
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.gi}
                            onChange={formikProps.handleChange}
                            name="gi"
                            label="GI"
                            disable={!attributeData?.editable.includes("gi")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.shd}
                            onChange={formikProps.handleChange}
                            name="shd"
                            label="SHD"
                            disable={!attributeData?.editable.includes("shd")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.phd}
                            onChange={formikProps.handleChange}
                            name="phd"
                            label="PHD"
                            disable={!attributeData?.editable.includes("phd")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.bu_gi}
                            onChange={formikProps.handleChange}
                            name="bu_gi"
                            label="BU GI"
                            disable={!attributeData?.editable.includes("bu_gi")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.ri_notifier}
                            onChange={formikProps.handleChange}
                            name="ri_notifier"
                            label="RI Notifier"
                            disable={!attributeData?.editable.includes("ri_notifier")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.gi_cleanup}
                            onChange={formikProps.handleChange}
                            name="gi_cleanup"
                            label="GI Cleanup"
                            disable={!attributeData?.editable.includes("gi_cleanup")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.lookup_functions}
                            onChange={formikProps.handleChange}
                            name="lookup_functions"
                            label="Lookup Function"
                            disable={!attributeData?.editable.includes("lookup_functions")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.cloud_inventory}
                            onChange={formikProps.handleChange}
                            name="cloud_inventory"
                            label="Cloud Inventory"
                            disable={!attributeData?.editable.includes("cloud_inventory")||isReadOnlyUser}
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.workspaces_cost_optimizer}
                            onChange={formikProps.handleChange}
                            name="workspaces_cost_optimizer"
                            label="Workspaces Cost Optimizer"
                            disable={!attributeData?.editable.includes("workspaces_cost_optimizer")||isReadOnlyUser}
                          />
                        </div>
                        <Divider className="col-12 col-xl-11 mb-2" />
                        <div className="col-12 col-xl-12 mb-3">
                          <Typography variant='h6'>Account Settings</Typography>
                        </div>
                        <div className="col-12 col-xl-4 mb-2">
                          <HOVInput
                            label="CFN HUP Interval (in seconds)"
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.cfn_hup_interval}
                            name="cfn_hup_interval"
                            form={formikProps}
                            xstyle={controlsStyle}
                            type="text"
                            description="CFN HUP interval in seconds"
                            disable={!attributeData?.editable.includes("cfn_hup_interval")||isReadOnlyUser}
                          />
                          {formikProps.errors.cfn_hup_interval &&
                            formikProps.touched.cfn_hup_interval ? (
                            <div className="text-danger">
                              {formikProps.errors.cfn_hup_interval}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-2">
                          <AutoCompleteWithChips
                            label="Default GI version "
                            value={formikProps.values.default}
                            onChange={(e) =>
                              formikProps.setFieldValue(`default`, [e])
                            }
                            name="default"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="defaultGIVersion"
                            description="Default GI version to use"
                            multi
                            disable={!attributeData?.editable.includes("default")||isReadOnlyUser}
                          />
                          {formikProps.errors.default &&
                            formikProps.touched.default ? (
                            <div className="text-danger">
                              {formikProps.errors.default}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.gi_v2}
                            onChange={formikProps.handleChange}
                            name="gi_v2"
                            label="GI v2 "
                            disable={!attributeData?.editable.includes("gi_v2")||isReadOnlyUser}
                            description="Mark true if GI v2 images are present"
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.gi_v3}
                            onChange={formikProps.handleChange}
                            name="gi_v3"
                            label="GI v3 "
                            disable={!attributeData?.editable.includes("gi_v3")||isReadOnlyUser}
                            description="Mark true if GI v3 images are present"
                          />
                        </div>
                        <div className="col-12 col-xl-12 mb-2">
                          <HOVCheckbox
                            value={formikProps.values.gi_monthly_pinned_release}
                            onChange={formikProps.handleChange}
                            name="gi_monthly_pinned_release"
                            label="GI Monthly Pinned Release "
                            disable={!attributeData?.editable.includes("gi_monthly_pinned_release") || isReadOnlyUser}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                        <div>
                          {
                            attributeData?.data[0]?.account_status !== "DELETED"
                              ? ((state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? (
                                <HOVButton
                                  value="Update"
                                  color="#fff"
                                  backgroundColor="#008542"
                                  border="none"
                                  className="px-1 py-2"
                                  buttonWidth={140}
                                  custom
                                  type="submit"
                                  onClick={formikProps.handleSubmit}
                                />
                              ) : null
                                
                              )
                              : ""
                          }
                        </div>
                        <span className="mx-3"></span>
                        <div>
                          <HOVButton
                            value="Cancel"
                            color="#fff"
                            backgroundColor="#FF4E50"
                            border="none"
                            className="px-1 py-2"
                            buttonWidth={140}
                            custom
                            onClick={(e) => {
                              handleEditAccountClose()
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )
            :
            <div style={{ height: "72vh" }}>
              <Loader />
            </div>
        }
        <Backdrop
          open={backdrop}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default EditAccount;
const regex = /^[iI]\d{5,7}$/
const emailRegex =/^[a-zA-Z0-9._%+-]+@verisk\.com$/i
const contactValidator = (data)=>{
  try{
    let results = data?.map((item)=>regex?.test(item?.label))
    let isTrue =  !results?.includes(false) ?  true : false
    return isTrue
  }
  catch(error){
    console.error(error)
    return false
  }
}
const emailValidator = (data)=>{
  try{
    let results = data?.map((item)=>emailRegex?.test(item?.label))
    let isTrue =  !results?.includes(false) ?  true : false
    return isTrue
  }
  catch(error){
    console.error(error)
    return false
  }
}
const ValidationSchema = Yup.object().shape({
  short_name: Yup.string("Please provide account short name").nullable(),
  account_owner: Yup.string().matches(
    regex,
    'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)'
  ).required("Please provide account owner name"),
  account_name: Yup.string().required("Please provide account name"),
  account_id: Yup.string("Please enter account id")
  .matches(/^[0-9]+$/,"Account ID must be only digits.")
  .min(12,"Account ID must be 12 digits.")
  .max(12,"Account ID must be 12 digits."),
  vertical: Yup.array()
  .of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select a vertical.'),
  okta_url: Yup.string().required("Please provide the okta URL")
  .matches(/^https:\/\/verisk\.okta\.com\/home\/amazon_aws\/[A-Za-z0-9]+\/\d+$/,"It must follow url pattern. (e.g., https://verisk.okta.com/home/amazon_aws/0kejdirbakjnscjubub/123)"),
  account_env: Yup.array()
  .of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select an environment.'),
  business_unit: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit.'),
  root_email: Yup.string().email("Please provide valid email id").required('Please provide root email id')
  .matches(/^[a-zA-Z0-9._%+-]+@verisk\.com$/i, "Please enter a valid email (e.g., xyz@verisk.com).")
  .nullable(),
  account_type: Yup.array()
  .of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select an account type.'),
  patch_management: Yup.array()
  .of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select a patch management.'),
  division: Yup.string().required("Please provide the division.")
  .matches(/^[0-9]+$/,"Division must be only digits."),
  costOptimizationEmail: Yup.array().test({
    name:"costOptimizationEmail",
    message:'Please enter a valid email (e.g., abc@verisk.com).',
    test: (value)=> emailValidator(value)
  }),
  owca: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  servicesHealthEmail: Yup.string().email("Please provide valid email id")
    .matches(/^[a-zA-Z0-9._%+-]+@verisk\.com$/i, "Please enter a valid email (e.g., xyz@verisk.com).")
    .nullable(),
  configAlertEmail: Yup.array().test({
    name:"configAlertEmail",
    message:'Please enter a valid email (e.g., xyz@verisk.com).',
    test: (value)=> emailValidator(value)
  }),
  configAlertEmailCc: Yup.array().test({
    name:"configAlertEmailCc",
    message:'Please enter a valid email (e.g., xyz@verisk.com).',
    test: (value)=> emailValidator(value)
  }),
  accessApprovers: Yup.array().test({
    name:"accessApprovers",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value) => contactValidator(value)
  }),
  accessReportReciepent: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(0, 'Please provide a valid email'),
  accessReportFrequency: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Please select an option'),
  businessContacts: Yup.array().test({
    name:"businessContacts",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value) => contactValidator(value)
  }),
  technicalContacts: Yup.array().test({
    name:"technicalContacts",
    message:'It must start with "i" or "I" followed by 5 to 7 digits (e.g., i45678, i3459451)',
    test: (value) => contactValidator(value)
  }),
  ou_name: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required()
      })
    )
    .min(1, "Please select an OU name"),
  account_status: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, "Please select account status"),
  business_unit_alignment: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ).min(1, 'Please select a business unit alignment.'),
  okta_profile_name: Yup.string().required("Please provide okta profile name")
  .matches(/^([^-]+)(-[^-]+)+$/,"Please provide - in between string."),
  regions: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
  ).min(1, 'Please select atleast one region')
});