const dashMock = [
  {
    title: 'accounts',
    img: '',
    notificationCount: 6,
    route: 'accounts'
  },
  {
    title: 'vpc',
    img: '',
    notificationCount: 0,
    route: 'vpc'
  },
  {
    title: 'business unit',
    img: '',
    notificationCount: 0,
    route: 'business-unit'
  },
  // {
  //     title: 'config rule exceptions',
  //     img: '',
  //     notificationCount: 7,
  //     route:'config-rule-exceptions'
  // },
  {
    title: 'CHEF Management (OWCA)',
    img: '',
    notificationCount: 6,
    route: 'config-management'
  },
  {
    title: 'golden images',
    img: '',
    notificationCount: 2,
    route: 'golden-images'
  }

]

export default dashMock;