import React from "react";
import Home from "./components/home/Home";
import { withOktaAuth } from "@okta/okta-react";
import DrawerContext from "./context/DrawerContext";
import loader from './assets/Images/loader.gif';

export default withOktaAuth(
  class Authentication extends React.Component {
    static contextType = DrawerContext;

    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    checkAuthentication = () => {
      if (this.props.authState?.isAuthenticated) {
        localStorage.setItem(
          "hov_token",
          this.props.authState?.accessToken.accessToken
        );
        localStorage.setItem(
          "hov_access_token",
          JSON.stringify(this.props.authState?.accessToken)
        );
      }
    };

    async login() {
      if (this.props.authState?.isAuthenticated) {
        localStorage.setItem("hov_token",
          this.props.authState?.accessToken.accessToken
        );
        localStorage.setItem(
          "hov_access_token",
          JSON.stringify(this.props.authState?.accessToken)
        );
      } else {
        await this.props.oktaAuth.signInWithRedirect();
      }
    }

    async logout() {
      await this.props.oktaAuth.signOut("/");
    }

    render() {
      const user = this.props.authState?.idToken
      const value = this.context;
      value.setUserInfo(user);
      if (this.props.authState?.isPending) {
        return (
          <div
            style={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={loader}
              alt="Loading..."
              style={{ height: 200, width: 200 }}
            />
          </div>
        );
      }
      else if (this.props.authState?.isAuthenticated) {
        return <Home oktaAuth={this.props.oktaAuth} logout={this.logout} />;
      }
      else {
        this.login();
        return (
          <div
            style={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={loader}
              alt="Loading..."
              style={{ height: 200, width: 200 }}
            />
          </div>
        );
      }
    }
  }
);