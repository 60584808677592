import React, { useContext } from 'react';
import {
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './TableActionButton.scss';
import { GlobalContext } from '../../App';
import { BU_USER, SUPER_USER } from '../../roles';

const TableActionButton = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const isReadOnlyUser = (state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? false : true
  return (
    <div className="action-btn">
      <div className="button">
        {
          !props.disabled
            ?
            (
              <Tooltip title= {isReadOnlyUser ? "View" : "Edit"} placement="top">
                <IconButton
                  className="btn"
                  disableRipple
                  onClick={() => props.handleOpen(props.editProps)}
                  style={{ color: "#616161" }}
                  size="large">
                    {isReadOnlyUser ? (<VisibilityIcon/>) : (<EditIcon color="inherit" />)}
                </IconButton>
              </Tooltip>
            )
            :
            (
              <IconButton
                className="btn"
                disableRipple
                disabled
                onClick={() => props.handleOpen(props.editProps)}
                style={{ color: "#616161" }}
                size="large">
                <EditIcon color="inherit" />
              </IconButton>
            )
        }
      </div>
      {
        props.children
      }
      <div className="button">
        {(state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? (
          <Tooltip title="Delete" placement="top">
          <IconButton
            style={{ color: "#ff5252" }}
            className="btn"
            disableRipple
            onClick={() => props.handleDeleteConfirmation(props.editProps)}
            size="large">
            <DeleteIcon color="inherit" />
          </IconButton>
        </Tooltip>
        ):null}
        
      </div>
    </div>
  );
};

export default TableActionButton;