import React, { useReducer} from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import Authentication from "./Authentication";
import { Security, LoginCallback } from "@okta/okta-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import DrawerContext from "./context/DrawerContext";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "./pages/error/error";

export const GlobalContext = React.createContext({
  state: {},
  dispatch: () => { },
});

export const pagesData = [
  {
    title: "DASHBOARD",
    route: "/",
    roles: ["USER", "ADMIN", "HR", "SUPER_ADMIN", "VPC"],
  },
  {
    title: "ACCOUNTS",
    route: "/accounts",
    roles: ["ADMIN", "SUPER_ADMIN"],
  },
  {
    title: "VPC",
    route: "/vpc",
    roles: ["HR", "ADMIN", "USER", "VPC"],
  },
  {
    title: "BUSINESS UNIT",
    route: "/business-unit",
    roles: ["ADMIN", "SUPER_ADMIN"],
  },
  // {
  //   title: "CONFIG RULE EXCEPTIONS",
  //   route: "/config-rule-exceptions",
  //   roles: ["USER"],
  // },
  {
    title: "CHEF MANAGEMENT (OWCA)",
    route: "/config-management",
    roles: ["ADMIN"],
  },
  {
    title: "GOLDEN IMAGES",
    route: "/golden-images",
    roles: ["USER"],
  },
];

// export const grantPermission = (userRoles, requestedRoles) => {
//   let hasValidPermission = false;
//   hasValidPermission = userRoles.some((userRole) =>
//     requestedRoles.includes(userRole)
//   );
//   return hasValidPermission;
// };

const initialState = {
  user: {
    name: "Test Tester",
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  accountsData: { "data": [] },
  accountsTableColumnVisibility: { selectedValues: ["Account ID"] },
  vpcData: { "data": [] },
  vpcFilterModel: null,
  businessUnitData: { "data": [] },
  configRuleData: [],
  configManagementData: { "data": [] },
  bugiData: { "data": [] },
  awsAccounts: { "data": [] },
  awsRegions: { "data": [] },
  bizUnits: { "data": [] },
  awsOs: { "data": [] },
  instanceTypes: { "data": [] },
  ouDeatils: { "data": [] },
  metadataDetails:{"data":[]}
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      };
    case "SET_VPC_DATA":
      return {
        ...state,
        vpcData: action.payload
      };
    case "SET_COLUMNS_VISIBILITY":
      return {
        ...state,
        accountsTableColumnVisibility: action.payload
      };
    case "SET_BUSINESSUNIT_DATA":
      return {
        ...state,
        businessUnitData: action.payload
      };
    case "SET_CONFIG_MANAGEMENT_DATA":
      return {
        ...state,
        configManagementData: action.payload
      };
    case "SET_ACCOUNT_DATA":
      return {
        ...state,
        accountsData: action.payload
      };
    case "SET_GI_DATA":
      return {
        ...state,
        bugiData: action.payload
      };
    case "SET_AWS_ACCOUNTS":
      return {
        ...state,
        awsAccounts: action.payload
      };
    case "SET_AWS_REGIONS":
      return {
        ...state,
        awsRegions: action.payload
      }
    case "SET_AWS_OS":
      return {
        ...state,
        awsOs: action.payload
      }
    case "SET_BU_LIST":
      return {
        ...state,
        bizUnits: action.payload
      }
    case "Set_Instance_Types_List":
      return {
        ...state,
        instanceTypes: action.payload
      }
    case "SET_OU_DETAILS":
      return {
        ...state,
        ouDeatils: action.payload
      }
    case "SET_METADATA":
      return {
        ...state,
        metadataDetails: action.payload?.data
      }
    default:
      return state;
  }
}

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/implicit/callback",
  scopes: ["groups", "profile", "openid", "email"],
  headers: { 'Access-Control-Allow-Origin': window.location.origin }
});

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [drawerState, setDrawerState] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(true);
  const [authToken, setAuthToken] = React.useState(true);
  const value = {
    drawerState,
    setDrawerState,
    userInfo,
    setUserInfo,
    authToken,
    setAuthToken,
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GlobalContext.Provider value={{ state, dispatch }}>
        <DrawerContext.Provider value={value}>
          <Router>
            <Security oktaAuth={oktaAuth}>
              <Route path="/" component={Authentication} />
              <Route path="/implicit/callback" component={LoginCallback} />
            </Security>
          </Router>
        </DrawerContext.Provider>
      </GlobalContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
