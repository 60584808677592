import React from 'react';
import { pagesData } from './App';
// import { useContext } from 'react';
// import { GlobalContext } from './App';
import Vpc from './pages/vpc/VPC';
// import { grantPermission } from './App';
import Accounts from './pages/accounts/Accounts';
import Dashboard from './components/dashboard/Dashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import BusinessUnit from './pages/businessUnit/BusinessUnit';
import ConfigManagement from './pages/configManagement/ConfigManagement';
import BUGI from './pages/bugi/BUGI';

const PrivateRoute = ({ children, isAuthenticated, roles, ...rest }) => {
  // const { state, dispatch } = useContext(GlobalContext);
  // const userRoles = state.user.roles;
  // const hasValidPermission = grantPermission(userRoles, roles);
  const shouldChildrenRender =
    (isAuthenticated) || rest.path === '/';
  return (
    <Route
      {...rest}
      render={({ location }) =>
        shouldChildrenRender ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Router = () => {
  const isAuthenticated = true;
  return (
    <Switch>
      <PrivateRoute
        path="/accounts"
        isAuthenticated={isAuthenticated}
        roles={
          pagesData.find((pageData) => pageData.route === '/accounts').roles
        }
      >
        <Accounts />
      </PrivateRoute>

      <PrivateRoute
        path="/vpc"
        isAuthenticated={isAuthenticated}
        roles={pagesData.find((pageData) => pageData.route === '/vpc').roles}
      >
        <Vpc />
      </PrivateRoute>

      <PrivateRoute
        path="/business-unit"
        isAuthenticated={isAuthenticated}
        roles={
          pagesData.find((pageData) => pageData.route === '/business-unit')
            .roles
        }
      >
        <BusinessUnit />
      </PrivateRoute>

      {/* <PrivateRoute
        path="/config-rule-exceptions"
        isAuthenticated={isAuthenticated}
        roles={
          pagesData.find(
            (pageData) => pageData.route === '/config-rule-exceptions'
          ).roles
        }
      >
        <ConfigRuleExceptions />
      </PrivateRoute> */}

      <PrivateRoute
        path="/config-management"
        isAuthenticated={isAuthenticated}
        roles={
          pagesData.find((pageData) => pageData.route === '/config-management')
            .roles
        }
      >
        <ConfigManagement />
      </PrivateRoute>

      <PrivateRoute
        path="/golden-images"
        isAuthenticated={isAuthenticated}
        roles={
          pagesData.find((pageData) => pageData.route === '/golden-images')
            .roles
        }
      >
        <BUGI />
      </PrivateRoute>

      <PrivateRoute
        path="/"
        isAuthenticated={isAuthenticated}
        roles={pagesData.find((pageData) => pageData.route === '/').roles}
      >
        <Dashboard />
      </PrivateRoute>
    </Switch>
  );
}

export default Router;
