import React from 'react';
// import makeStyles from '@mui/styles/makeStyles';
import { makeStyles } from 'tss-react/mui';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import './HOVMultiSelectDropdown.scss';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  select: {
    border: 'none',
    background: "yellow"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
};

const defaultDropdownOptions = [
  'Account Name',
  'Account ID',
];



export default function HOVMultiSelectDropdown({ onChange, width = 22, height = 22, dropdownOptions = defaultDropdownOptions }) {
  // const classes = useStyles();
  const { classes } = useStyles();
  const [selectedValues, setSelectedValues] = React.useState(dropdownOptions);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    const tempSelected = event.target.value;
    const deselctedValues = dropdownOptions.filter(option => !tempSelected.includes(option))
    onChange(tempSelected, deselctedValues);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // const btnStyles = { width, height }

  return (
    <div className={'attribute-select'}>
      <span className="configText">Select</span>
      <span className="configText mx-1">Attributes</span>
      <IconButton
        aria-label="delete"
        className={classes.margin}
        onClick={handleOpen}
        disableRipple
        size="large"
        >
        <SettingsIcon color='info'/>
      </IconButton>
      <FormControl className={classes.formControl + ' w-0 invisible'}>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => onChange(selected, dropdownOptions.filter(option => !selected.includes(option)))}
          MenuProps={MenuProps}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          color='primary'

        >
          {dropdownOptions.map((dropdownOption) => (
            <MenuItem key={dropdownOption} value={dropdownOption} >
              <Checkbox checked={selectedValues.indexOf(dropdownOption) > -1} style={{
                color: "#2A7DE1",
              }} />
              <ListItemText primary={dropdownOption} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}