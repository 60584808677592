import { useState, useContext } from "react";
import { AxiosClient } from "../lib/http";
import { GlobalContext } from "../App";

const useApiCalls = () => {
  const [useHttpStatus, setHttpStatus] = useState(401);
  const [useMessage, setMessage] = useState("");
  const [useSeverity, setSeverity] = useState("success");
  const [useError, setError] = useState(false);
  const [useErrorCode, setErrorCode] = useState("");
  const [useOpen, setOpen] = useState(false);
  const [useAttributeData, setAttributeData] = useState(null);
  const { dispatch } = useContext(GlobalContext);


  // Get table data
  const getTableData = async (urlPath, stateType) => {
    try {
      const response = await AxiosClient.get(`/${urlPath}`);
      if (response.data.length) {
        setHttpStatus(200);
        dispatch({ type: stateType, payload: response });
      }
      else if(response?.status === 200 && stateType === "SET_METADATA"){
        setHttpStatus(200);
        dispatch({ type: stateType, payload: response });
      }
      else {
        setOpen(true);
        setSeverity("info");
        setMessage("No data found");
      }
    }
    catch (error) {
      setError(true);
      if (error?.response?.status) {
        setErrorCode(error.response.status);
      }

      else {
        setErrorCode('404');
        setMessage(error.message);
      }
    }
  };


  // Get form data
  const getFormData = async (urlPath) => {
    try {
      const response = await AxiosClient.get(`/${urlPath}`);
      if (response.status === 200 || response.status === 201) {
        setAttributeData(response.data);
        setHttpStatus(response.status);
      }
      else {
        setError(true);
        setOpen(true);
        setMessage(response.data.message)
        setSeverity("error")
        setHttpStatus(response.status);
      }
    }

    catch (error) {
      setError(true);
      setOpen(true);
      setSeverity("error")
      setHttpStatus(error.response);

      if (error.response.data.message) {
        setMessage(error.response.data.message);
      }
      else {
        setMessage("Invalid request");
      }
    }
  };

  // Update form data
  const updateFormData = async (urlPath, values, stateType, baseUrlPath) => {
    setOpen(false);
    setHttpStatus('');
    setSeverity('success');
    setMessage('');

    try {
      const response = await AxiosClient.put(`/${urlPath}`, { data: values });

      if (response.status === 201 || response.status === 200) {
        setHttpStatus(response.status);
        setOpen(true);
        setMessage(response.data.message);
        setSeverity("success");
        getTableData(baseUrlPath, stateType);
        getTableData('accounts', 'SET_AWS_ACCOUNTS');
        getTableData('businessunit', 'SET_BU_LIST');
      }
      else {
        setHttpStatus(response.status);
        setOpen(true);
        setMessage(response.data.message);
        setSeverity("error");
      }
    }

    catch (error) {
      setOpen(true);
      setSeverity("error");

      if (error.response?.status) {
        setHttpStatus(error.response.status);
        setMessage(error.response.data.message);
      }
      else {
        setHttpStatus(404);
        setMessage(error.message);
      }
    }
  };


  // For adding new record
  const addRecord = async (urlPath, values, stateType) => {
    setOpen(false);
    setHttpStatus('');
    setMessage('');
    setSeverity('success');
    try {
      const response = await AxiosClient.post(`/${urlPath}`, { data: values });

      if (response.status === 201 || response.status === 200) {
        setHttpStatus(response.status);
        setOpen(true);
        setMessage(response.data.message);
        setSeverity("success");
        getTableData(urlPath, stateType);
        getTableData('accounts', 'SET_AWS_ACCOUNTS');
        getTableData('businessunit', 'SET_BU_LIST');
      }

      else {
        setHttpStatus(response.status);
        setOpen(true);
        setMessage(response.data.message);
        setSeverity("error");
      }
    }

    catch (error) {
      setOpen(true);
      setSeverity("error");

      if (error.response?.status) {
        setHttpStatus(error.response.status);
        setMessage(error.response.data.message);
      }
      else {
        setHttpStatus(404);
        setMessage(error.message);
      }
    }
  };

  // For deleting record
  const deleteRecord = async (urlPath, stateType, baseUrlPath) => {
    setOpen(false);
    setHttpStatus('');
    setMessage('');
    setSeverity('success');

    try {
      const response = await AxiosClient.delete(`/${urlPath}`);

      setHttpStatus(response.status);
      setOpen(true);
      setMessage(response.data.message);

      if (response.status === 201 || response.status === 200) {
        setSeverity("success");
        getTableData(baseUrlPath, stateType);
        getTableData('accounts', 'SET_AWS_ACCOUNTS');
        getTableData('businessunit', 'SET_BU_LIST');
      }
      else {
        setSeverity("error");
      }
    }

    catch (error) {
      setOpen(true);
      setSeverity("error");

      if (error.response?.status) {
        setHttpStatus(error.response.status);
        setMessage(error.response.data.message);
      }
      else {
        setHttpStatus(404);
        setMessage(error.message);
      }
    }
  };

  const getRequest = async (urlPath) => {
    setOpen(false);
    setHttpStatus('');
    setMessage('');
    setSeverity('success');
    try {
      const response = await AxiosClient.get(`/${urlPath}`);

      setOpen(true);
      setSeverity("success");
      setMessage(response.data?.message);
      setHttpStatus(response.status);
    }
    catch (error) {
      setOpen(true);
      setSeverity("error");

      if (error.response?.status) {
        setHttpStatus(error.response?.status);
        setMessage(error.response?.data?.message);
      }
      else {
        setHttpStatus(404);
        setMessage(error.message);
      }
    }
  }

  const getInstanceTypes = async (urlPath, stateType) => {
    setOpen(false);
    setHttpStatus('');
    setMessage('');
    setSeverity('success');
    try {
      setOpen(true); 
      const response = await AxiosClient.get(`/${urlPath}`);

      if (response.data.length) {
        setHttpStatus(200);
        dispatch({ type: stateType, payload: response });
      }
      else {
        setOpen(true);
        setSeverity("info");
        setMessage("No data found");
      }
    }
    catch (error) {
      setOpen(true);
      setSeverity("error");

      if (error.response?.status) {
        setHttpStatus(error.response?.status);
        setMessage(error.response?.data?.message);
      }
      else {
        setHttpStatus(404);
        setMessage(error.message);
      }
    }
  }

  return {
    getTableData,
    getFormData,
    updateFormData,
    addRecord,
    deleteRecord,
    getRequest,
    useHttpStatus,
    useMessage,
    useSeverity,
    useError,
    useErrorCode,
    useOpen,
    useAttributeData,
    getInstanceTypes
  };

};

export default useApiCalls;