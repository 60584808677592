import React from 'react';
import Button from 'react-bootstrap/Button'
import './Error.scss';

const Error = ({
  message,
  code
}) => {

  const style = {
    borderRight: "3px solid black",
    padding: 30,
    marginRight: 20
  }
  return (
    <div className="Error">
      <header className="Err-header">
        <h3 className="Err-code" style={style}>{code}</h3>
        <div className="Err-message-block">
          <h3>Something went wrong</h3>
          <h6 className="message">{message}</h6>
          <Button href="/">Home</Button>
        </div>
      </header>
    </div>
  );
};

Error.defaultProps = {
  message: 'Page not found',
  code: '404'
};

export default Error;